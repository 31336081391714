import { ApiErrorResponse, ApiErrorType } from '@/types';
import './input-group.scss';
import { cns } from '@/utils/array';
import Alert from '../alert/alert';
import { cloneElement, useMemo, useRef } from 'react';

export interface InputGroupProps {
    children: React.ReactNode;
    dir?: 'h' | 'v' | 'initial';
    className?: string;
    error?: ApiErrorResponse | null;
    id?: string;
    errorFields?: string[];
}

export default function InputGroup({ dir, children, className, error, id, errorFields }: InputGroupProps) {
    let c = useRef(children);
    let hasError = id && error && error.error_type === ApiErrorType.Validation && (error.field === id || (error.field !== null && errorFields?.includes(error.field)));

    let classes = ['input-group', hasError ? 'has-error' : ''];

    switch (dir) {
        case 'h':
            classes.push('h');
            break;
        case 'v':
            classes.push('v');
            break;
        case 'initial':
            break
        default:
            classes.push('v');
            break;
    }

    if (className) classes.push(className)

    if (!c.current) {
        if (Array.isArray(c.current)) {
            let newChildren = c.current.map((child) => {
                if (!child?.type) return child;
    
                let childProps = {
                    ...child.props
                };
    
                if (child.type.name === 'Label' || child.type.render?.name === 'Label') {
                    if (typeof childProps.htmlFor === 'undefined') {
                        childProps.htmlFor = id;
                    }
                } else {
                    if (typeof childProps.id === 'undefined') {
                        childProps.id = id;
                    }
    
                    if (typeof childProps.hasError === 'undefined') {
                        childProps.hasError = false;
                    } else {
                        childProps.hasError = hasError;
                    }
                }
    
                return cloneElement(child, childProps);
            });
    
            let labelIndex = newChildren.findIndex((child) => child?.type?.name === 'Label');
    
            if (labelIndex > -1) {
                let alertIndex = newChildren.findIndex((child) => child?.type?.name === 'Alert');
    
                if (hasError) {
                    if (alertIndex > -1) {
                        newChildren[alertIndex] = <Alert key={error?.message} plain type='error'>{error?.message}</Alert>;
                    } else {
                        newChildren.splice(labelIndex + 1, 0, <Alert key={error?.message} plain type='error'>{error?.message}</Alert>);
                    }
                } else {
                    if (alertIndex > -1) {
                        newChildren.splice(alertIndex, 1);
                    } else {
                        newChildren.splice(labelIndex + 1, 0, null);
                    }
                }
            } else {
                let alertIndex = newChildren.findIndex((child) => child?.type?.name === 'Alert');
    
                if (hasError) {
                    if (alertIndex > -1) {
                        newChildren[alertIndex] = <Alert key={error?.message} plain type='error'>{error?.message}</Alert>;
                    } else {
                        newChildren.unshift(<Alert key={error?.message} plain type='error'>{error?.message}</Alert>);
                    }
                } else {
                    if (alertIndex > -1) {
                        newChildren.splice(alertIndex, 1);
                    } else {
                        newChildren.unshift(null);
                    }
                }
            }
    
            c.current = newChildren
        } else {
            let tempChildren = [];
    
            if (hasError) {
                tempChildren.push(<Alert key={error?.message} plain type='error'>{error?.message}</Alert>);
            }
    
            tempChildren.push(c.current);
    
            tempChildren.map((child: any) => {
                if (!child?.type) return child;
    
                if (child.type.name === 'Label' || child.type.render?.name === 'Label') {
                    if (!child.props.htmlFor) {
                        child.props.htmlFor = id;
                    }
                } else {
                    if (!child.props.id) {
                        child.props.id = id;
                    }
    
                    if (!child.props.hasError) {
                        child.props.hasError = hasError;
                    }
                }
    
                return child;
            });
    
            c.current = tempChildren;
        }
    }

    return (
        <div className={cns(classes)}>
            {c.current}
        </div>
    )
}