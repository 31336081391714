import { useCreateJournalEntryApi, useGetJournalEntriesApiBackground, useUpdateJournalEntryApi } from "@/api/endpoints";
import Button from "@/components/atoms/button/button";
import Form from "@/components/atoms/form/form";
import InputSelect, { InputSelectRef } from "@/components/atoms/input-select/input-select";
import InputSelectOption from "@/components/atoms/input-select/input-select-option";
import useEntries from "@/hooks/use-entries";
import { ApiErrorResponse, EntryType, JournalEntry, UpdateJournalEntryPayload } from "@/types";
import { useMemo, useRef, useState } from "react";
import CreateChecklistEntry from "./create-checklist-entry";
import useForm from "@/hooks/use-form";
import './create-entry.scss';
import InputGroup from "@/components/atoms/input-group/input-group";
import FormButtonGroup from "@/components/atoms/form-button-group/form-button-group";
import { isDescendant } from "@/utils/elements";
import InputMultiline, { InputMultilineRef } from "@/components/atoms/input-multiline/input-multiline";
import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";
import CreateTrackingEntry from "./create-tracking-entry";
import { EventAction, EventSubject, useUmami } from "@/hooks/use-umami";

export interface CreateEntryProps {
    entry?: JournalEntry;
    editing?: boolean;
    setIsEditing?: (editing: boolean) => void;
    afterUpdate?: () => void;
    afterCreate?: () => void;
    parentId?: string;
    thread?: boolean;
}

export default function CreateEntry(props: CreateEntryProps) {
    const createInputRef = useRef<InputMultilineRef>(null);
    const createSelectRef = useRef<InputSelectRef>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [updateJournalEntry] = useUpdateJournalEntryApi();
    const [createJournalEntry] = useCreateJournalEntryApi();
    const [getJournalEntriesBackground] = useGetJournalEntriesApiBackground();
    const { setEntries, setError } = useEntries();
    const [type, setTypeInternal] = useState<EntryType>(props.entry?.entry_type || EntryType.Basic);
    const [metadata, setMetadata] = useState<any>(props.entry?.metadata || {});
    const { formError, setFormError } = useForm();
    const { user, entriesDate } = useData<ServerStateData>();
    const { trackEvent } = useUmami();

    const setType = (type: EntryType) => {
        setTypeInternal(type);
        setMetadata({});
    }
    
    const handleCreateEntry = (data: { content: string }) => {
        setFormError(null);
        createJournalEntry({ 
            content: data.content,
            metadata,
            entry_type: type,
            parent_id: props.parentId || null
        }).then(() => {
            trackEvent({
                action: EventAction.Create,
                subject: EventSubject.JournalEntry,
                meta: {
                    type,
                    thread: !!props.parentId
                }
            });
            createInputRef.current?.setValue("");
            createSelectRef.current?.setValue(EntryType.Basic);
            setType(EntryType.Basic);
            if (props.afterCreate) {
                props.afterCreate();
            } else {
                getJournalEntriesBackground({
                    date: entriesDate,
                    timezone: user!.timezone,
                }).then(setEntries).catch(setError)
            }
            setIsCreating(false);
            if (props.thread) {
                props.setIsEditing?.(false);
            }
        }).catch((error: ApiErrorResponse) => {
            setFormError(error);
        });
    }

    const handleUpdateEntry = (data: { content: string }) => {
        if (!props.entry) return;

        let payload: UpdateJournalEntryPayload = {
            content: data.content,
            metadata,
            pinned: props.entry.pinned,
            entry_type: type,
        };

        updateJournalEntry(props.entry.id, payload)
            .then(() => {
                trackEvent({
                    action: EventAction.Update,
                    subject: EventSubject.JournalEntry,
                    meta: {
                        type,
                        thread: !!props.parentId
                    }
                });

                if (props.afterUpdate) {
                    props.afterUpdate();
                } else {
                    getJournalEntriesBackground({
                        date: entriesDate,
                        timezone: user!.timezone
                    }).then(setEntries).catch(setError)
                }
                props.setIsEditing?.(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const extraDetails = useMemo(() => {
        switch (type) {
            case EntryType.Basic:
                return null;
            case EntryType.Checklist:
                return <CreateChecklistEntry metadata={metadata} setMetadata={setMetadata} />;
            case EntryType.Tracking:
                return <CreateTrackingEntry metadata={metadata} setMetadata={setMetadata} />;
            default:
                return null;
        }
    }, [type, props.entry, metadata])

    const handleSubmit = props.editing && !props.thread ? handleUpdateEntry : handleCreateEntry;

    const handleCreateChange = () => {
        if (!props.editing) {
            setIsCreating(true);
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit} onChange={handleCreateChange} id={"new-entry-form"} error={formError} errorOn='all'>
                <InputGroup id='content'>
                    <InputMultiline
                        ref={createInputRef}
                        defaultValue={props.entry?.content || ""}
                        placeholder={
                            props.thread ? "What else did you have in mind?" :
                            "What's on your mind?"
                        }
                        onFocus={() => {
                            if (props.editing) return;
                            setIsCreating(true)
                        }}
                        onBlur={(e) => {
                            if (!isDescendant(document.getElementById('new-entry-form')!, e.relatedTarget as HTMLElement)
                                && createInputRef.current?.target.value === "") {
                                setIsCreating(false)
                                setType(EntryType.Basic);
                            }
                        }}
                    />
                </InputGroup>
                {(isCreating || props.editing )&& (
                    <>
                        <InputGroup className={`${extraDetails ? 'attached' : ''}`} id='entry-type' dir='h'>
                            <InputSelect
                                ref={createSelectRef}
                                defaultValue={props.entry?.entry_type || type}
                                onChange={(value) => {
                                    setType(value as EntryType)

                                    if (!props.editing) {
                                        setIsCreating(true);
                                    }
                                }}
                            >
                                <InputSelectOption value={EntryType.Basic}>Basic</InputSelectOption>
                                <InputSelectOption value={EntryType.Checklist}>Checklist</InputSelectOption>
                                <InputSelectOption value={EntryType.Tracking}>Tracking</InputSelectOption>
                            </InputSelect>
                        </InputGroup>
                        {extraDetails}
                        <FormButtonGroup>
                            <Button type="submit">
                                {props.editing ? "Save" : "Submit"}
                            </Button>
                            {(props.editing || isCreating) && (
                                <Button type="button" style='gray' onClick={() => {
                                    createInputRef.current?.setValue('');
                                    createSelectRef.current?.setValue(EntryType.Basic);
                                    setType(EntryType.Basic);

                                    if (isCreating) {
                                        setIsCreating(false)
                                    } else {
                                        props.setIsEditing?.(false)
                                    }
                                }}>
                                    Cancel
                                </Button>
                            )}
                        </FormButtonGroup>
                    </>
                )}
            </Form>
            {isCreating && <hr />}
        </>
    )
}