import LandingLayout from "@/components/molecules/landing-layout/landing-layout";
import './landing.scss';
import Button from "@/components/atoms/button/button";
import SellingSection, { SellingSectionProps } from "./selling-section";
import Link from "@/components/atoms/link/link";
import LandingHeader from "./landing-header";
import { MdOutlineNewspaper, MdOutlineSearch, MdOutlineTag } from "react-icons/md";
import useTitle from "@/hooks/use-title";

const features: SellingSectionProps[] = [
    {
        icon: <MdOutlineTag />,
        section: 'Tagging',
        heading: 'Organize your notes with tags',
        description: 'Tag your notes to keep them organized and easy to find.',
        image: '/tagging.mov',
        imageType: 'video'
    },
    {
        icon: <MdOutlineSearch />,
        section: 'Search',
        heading: 'Find your notes quickly',
        description: 'Search your notes by tags or keyword to find what you need quickly and easily.',
        image: '/search.mov',
        imageType: 'video',
    },
    {
        icon: <MdOutlineNewspaper />,
        section: 'Entries',
        heading: 'Take advantage of entry types',
        description: 'Use different types of entries to keep your notes organized and easy to find.',
        image: '/entries.mov',
        imageType: 'video',
    }
]

export default function Landing() {
    return (
        <LandingLayout>
            <LandingHeader />
            <div className="intro">
                <h1>
                    LighterMind
                </h1>
                <p>
                    A short form journaling and note taking app
                </p>
                <Link className='cta-link' to='/register'>Sign Up</Link>
            </div>
            <div className='hero-image'>
                <div className='hero-image-border'>
                    <img src='/lightermind-hero.png' alt='journaling on a device' />
                </div>
            </div>
            <div className="features">
                {features.map((feature, index) => (
                    <SellingSection
                        key={index}
                        {...feature}
                        blue={index % 2 === 0}
                    />
                ))}
            </div>
            <div className="cta">
                <h2>
                    Ready to get started?
                </h2>
                <Link className="cta-link" to='/register'>
                    Sign Up
                </Link>
            </div>
        </LandingLayout>
    )
}