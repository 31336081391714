import { useEffect, useState } from "react"
import { ChecklistItem, ChecklistStatus } from "../entry/checklist-entry/checklist-item"
import Button from "@/components/atoms/button/button";
import InputText from "@/components/atoms/input-text/input-text";
import { JournalEntry } from "@/types";
import { ChecklistMetadata } from "../entry/checklist-entry/checklist-entry";
import InputGroup from "@/components/atoms/input-group/input-group";
import { MdAdd, MdRemove } from "react-icons/md";
import './create-checklist-entry.scss'

export interface CreateChecklistEntryProps {
    setMetadata: (metadata: any) => void;
    metadata?: ChecklistMetadata;
}

export default function CreateChecklistEntry({ setMetadata, metadata }: CreateChecklistEntryProps) {
    const [items, setItems] = useState<ChecklistItem[]>(
        metadata?.items && metadata?.items.length > 0 ? metadata.items : 
        [{
            id: Math.random().toString(36).substring(7),
            text: "",
            status: ChecklistStatus.Todo
        }]
    );

    const addItem = () => {
        const id = Math.random().toString(36).substring(7);
        const newItem = { id, text: "", status: ChecklistStatus.Todo };
        setItems((last) => [...last, newItem]);
    }

    const updateItem = (id: string, text: string) => {
        setItems((last) => last.map((item) => {
            if (item.id === id) {
                return { ...item, text };
            }
            return item;
        }));
    }

    const removeItem = (id: string) => {
        setItems((last) => last.filter((item) => item.id !== id));
    }

    useEffect(() => {
        let filteredItems = items.filter((item) => item.text !== "");

        setMetadata({ items: filteredItems });
    }, [items, setMetadata]);

    return (
        <div className="create-checklist-form">
            <div>
                <Button className='add-checklist-item' style='custom' onClick={addItem}>
                    <MdAdd />
                    Add Item
                </Button>
            </div>
            <ul className='checklist-items'>
                {items.map((item) => (
                    <li key={item.id}>
                        <InputGroup className='checklist-item-group' dir='h'>
                            <InputText
                                id={`${item.id}-text`}
                                label={""}
                                defaultValue={item.text || ""}
                                placeholder="Always things to do..."
                                onChange={(value: string) => updateItem(item.id, value)}
                            />
                            <Button className="remove-checklist-item" style='custom' onClick={() => removeItem(item.id)}>
                                <MdRemove />
                            </Button>
                        </InputGroup>
                    </li>
                ))}
            </ul>
        </div>
    )
}