export default {
    '/login': { title: 'Login', header: 'Login' },
    '/register': { title: 'Register', header: 'Register' },
    '/server-error': { title: null, header: null },
    '/': { title: 'LighterMind - A short form journaling and note taking app', header: 'LighterMind' },
    '/journal': { title: 'Journal', header: 'Journal' },
    '/search': { title: 'Search', header: 'Search' },
    '/settings': { title: 'Settings', header: 'Settings' },
    '/journal/entry/:id': { title: 'Entry', header: 'Entry' }
}
