import { Link, Outlet } from 'react-router-dom';
import './sign-layout.scss';
import { ReactSVG } from 'react-svg';
import { usePage } from '../page-layout/page-layout-context';

export default function SignLayout() {
    usePage();
    return (
        <div className="sign-layout">
            <div className="sign-layout-inner">
                <Link to='/'>
                    <ReactSVG src='/lightermind-logo.svg' className='logo' />
                </Link>
                <Outlet />
            </div>
        </div>
    )

}