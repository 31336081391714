import { MdOutlineCalendarMonth, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import Button from "../button/button";
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from "react";
import './calendarpicker.scss'
import { cns } from "@/utils/array";
import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";
import Form from "../form/form";
import InputText from "../input-text/input-text";
import FormButtonGroup from "../form-button-group/form-button-group";
import InputGroup from "../input-group/input-group";

export type CalendarPickerProps = {
    buttonClass: string,
    date?: string,
    clearDate?: number | null,
    onClick: (date: string) => void,
};

type CalendarDate = {
    date: moment.Moment,
    day: number,
    ymd: string,
} | null;

export default function CalendarPicker(props: CalendarPickerProps) {
    let givenDate: Moment | null = props.date ? moment(props.date) : moment();
    if (!givenDate.isValid()) givenDate = null;

    const [selectedDate, setSelectedDate] = useState<Moment | null>(givenDate);
    const [date, setDate] = useState(givenDate ? givenDate : moment());
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);
    const today = moment();
    const { entryDates } = useData<ServerStateData>();
    const dates = [...new Set((entryDates || []).map((date) => moment.utc(date).local().format('YYYY-MM-DD')))]
    const [typingDate, setTypingDate] = useState<boolean>(false)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [])

    useEffect(() => {
        setSelectedDate(givenDate);
    }, [props.date])

    useEffect(() => {
        if (props.clearDate) {
            setSelectedDate(null);
            setDate(moment());
            setIsOpen(false);
        }
    }, [props.clearDate])

    // get the days in the month/year as a matrix of weeks
    // each week is an array of days
    // each day is an object with a date and a day of the week
    // each week must have 7 days with null for days that are not in the month
    const getDaysInMonth = (month: number, year: number): CalendarDate[][] => {
        let date = moment().year(year).month(month).date(1);
        let weeks: CalendarDate[][] = [];

        while (date.month() === month) {
            let week: CalendarDate[] = Array.from({ length: 7 });

            // pad first week with nulls until the first day of the month
            if (weeks.length === 0) {
                for (let i = 0; i < date.day(); i++) {
                    week[i] = null;
                }
            }

            // fill in the days of the month
            for (let i = date.day(); i < 7 && date.month() === month; i++) {
                if (date.month() !== month) week[i] = null;
                else {
                    week[i] = {
                        date: date.clone(),
                        day: date.day(),
                        ymd: date.clone().format('YYYY-MM-DD'),
                    };
                }
                date.add(1, 'days');
            }

            let hasUndefiend = week.findIndex((day) => day === undefined);
            if (hasUndefiend !== -1) {
                // insert nulls where days are undefined
                for (let i = hasUndefiend; i < 7; i++) {
                    week[i] = null;
                }
            }

            weeks.push(week);
        }

        return weeks;
    }

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className='calendar-picker' ref={wrapperRef}>
            <Button
                style='custom'
                className={props.buttonClass}
                onClick={toggleOpen}
            >
                <MdOutlineCalendarMonth />
                {selectedDate && (
                    <span className='selected-date'>
                        {selectedDate.format('MM/DD/YYYY')}
                    </span>
                )}
            </Button>

            {isOpen && (
                <div className='calendar' ref={calendarRef}>
                    <div className='calendar-header'>
                        <Button
                            style='custom'
                            onClick={() => setDate(date.clone().subtract(1, 'month'))}
                        >
                            <MdOutlineChevronLeft />
                        </Button>
                        <Button
                            style='custom'
                            onClick={() => setTypingDate(true)}
                        >
                            {date.format('MMMM YYYY')}
                        </Button>
                        <Button
                            style='custom'
                            onClick={() => setDate(date.clone().add(1, 'month'))}
                        >
                            <MdOutlineChevronRight />
                        </Button>
                    </div>

                    {!typingDate ? (
                        <table className='calendar-table'>
                            <thead>
                                <tr>
                                    <th>S</th>
                                    <th>M</th>
                                    <th>T</th>
                                    <th>W</th>
                                    <th>T</th>
                                    <th>F</th>
                                    <th>S</th>
                                </tr>
                            </thead>

                            <tbody>
                                {getDaysInMonth(date.month(), date.year()).map((week, i) => (
                                    <tr key={i}>
                                        {week.map((day, j) => {
                                            if (!day) {
                                                return <td key={j}></td>;
                                            }

                                            return (
                                                <td key={j}>
                                                    <Button
                                                        className={
                                                            cns([
                                                                'date-button',
                                                                day.date.isSame(today, 'day') ? 'today' : '',
                                                                dates.includes(day.ymd) ? 'has-entries' : '',
                                                                day.date.isSame(selectedDate, 'day') ? 'selected' : '',
                                                            ])
                                                        }
                                                        style='custom'
                                                        onClick={() => {
                                                            setIsOpen(false);
                                                            setDate(day.date);
                                                            setSelectedDate(day.date);
                                                            props.onClick?.(day.date.format('YYYY-MM-DD'))
                                                        }}
                                                    >
                                                        {day.date.format('D')}
                                                    </Button>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <Form id='md-calendar-form' onSubmit={({ month, year }: { month: string, year: string}) => {
                            setDate(date.clone().month(parseInt(month) - 1).year(parseInt(year)));
                            setTypingDate(false);
                        }}>
                            <div className='calendar-inputs'>
                                <InputGroup id='month'>
                                    <InputText
                                        id='month'
                                        placeholder="MM"
                                        defaultValue={date.format("MM")}
                                        type='number'
                                        autoComplete="month"
                                        pattern="[0-9]*"
                                        max={12}
                                        min={1}
                                    />
                                </InputGroup>

                                <InputGroup id='year'>
                                    <InputText
                                        id='year'
                                        placeholder="YYYY"
                                        defaultValue={date.format("YYYY")}
                                        autoComplete='year'
                                        type='number'
                                        max={3000}
                                        min={2000}
                                    />
                                </InputGroup>
                            </div>

                            <FormButtonGroup>
                                <Button type='submit'>Submit</Button>
                                <Button type='button' onClick={() => setTypingDate(false)}>Cancel</Button>
                            </FormButtonGroup>
                        </Form>
                    )}

                    {selectedDate && !typingDate && (
                        <Button
                            onClick={() => {
                                setSelectedDate(null);
                                setDate(moment());
                                setIsOpen(false);
                            }}
                        >
                            Clear
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}