import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";
import { JournalEntry } from "@/types";

export default function useEntries() {
    const { setData } = useData<ServerStateData>();
    
    const setEntries = (entries: JournalEntry[]) => {
        setData('entries', entries);
    }

    const setError = (error: Error) => {
        setData('entryDataError', error.message);
    }

    return {
        setEntries,
        setError
    }
}