import { Link } from "react-router-dom";
import './landing-header.scss';
import { ReactSVG } from "react-svg";

export default function LandingHeader() {
    return (
        <div className='landing-header'>
            <ReactSVG src='/lightermind-logo.svg' className='logo' />
            <div className='links'>
                <Link to='/login'>Login</Link>
                <Link to='/register' data-umami-event="Signup button">Sign Up</Link>
            </div>
        </div>
    )
}