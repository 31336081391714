import { ApiErrorResponse } from "@/types";
import { useState } from "react";

export default function useForm() {
    const [formError, setFormError] = useState<ApiErrorResponse | null>(null);

    return {
        formError,
        setFormError
    }
}