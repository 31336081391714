import { EntryType, JournalEntry, UpdateJournalEntryPayload } from "@/types";
import BasicEntry from "./basic-entry";
import { memo } from "react";
import ChecklistEntry from "./checklist-entry/checklist-entry";
import { useGetJournalEntriesApiBackground, useUpdateJournalEntryApi } from "@/api/endpoints";
import useEntries from "@/hooks/use-entries";
import './entry.scss';
import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";
import TrackingEntry from "./tracking-entry/tracking-entry";

export interface EntryProps {
    entry: JournalEntry;
    entryThread?: JournalEntry[];
    fromThread?: boolean;
    fromSearch?: boolean;
    afterUpdate?: () => void;
}

function Entry(props: EntryProps) {
    const [updateJournalEntry] = useUpdateJournalEntryApi();
    const [getJournalEntriesBackground] = useGetJournalEntriesApiBackground();
    const { setEntries, setError } = useEntries();
    const { entriesDate, user } = useData<ServerStateData>();

    const updateEntry = (entry: JournalEntry) => {
        let payload: UpdateJournalEntryPayload = {
            content: entry.content,
            metadata: entry.metadata,
            pinned: entry.pinned,
            entry_type: entry.entry_type
        };

        updateJournalEntry(entry.id, payload)
            .then(() => {
                if (props.afterUpdate) {
                    props.afterUpdate();
                } else {
                    getJournalEntriesBackground({
                        date: entriesDate,
                        timezone: user!.timezone
                    }).then(setEntries).catch(setError)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    switch (props.entry.entry_type) {
        case EntryType.Basic:
            return <BasicEntry updateEntry={updateEntry} {...props} />
        case EntryType.Checklist:
            return <ChecklistEntry updateEntry={updateEntry} {...props} />
        case EntryType.Tracking:
            return <TrackingEntry updateEntry={updateEntry} {...props} />
        default:
            return <BasicEntry updateEntry={updateEntry} {...props} />
    }
}

export default memo(Entry);