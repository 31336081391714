import { useGetMeApi, useUpdateMeApi } from "@/api/endpoints";
import Button from "@/components/atoms/button/button";
import FormButtonGroup from "@/components/atoms/form-button-group/form-button-group";
import Form from "@/components/atoms/form/form";
import InputGroup from "@/components/atoms/input-group/input-group";
import InputSelect from "@/components/atoms/input-select/input-select";
import InputSelectOption from "@/components/atoms/input-select/input-select-option";
import SettingsSection, { SettingsSectionEditProps } from "@/components/molecules/settings-section/settings-section";
import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";

function Edit({ close }: SettingsSectionEditProps) {
    const { user, setData } = useData<ServerStateData>();
    const [getMe, { loading: loadingMe }] = useGetMeApi();
    const [updateMe, { loading: loadingUpdate }] = useUpdateMeApi();
    const timezones = (Intl as any).supportedValuesOf('timeZone').filter((timezone: string) => timezone.startsWith('America'));

    const handleSubmit = async (data: any) => {
        await updateMe({
            first_name: null,
            last_name: null,
            timezone: data.timezone
        });
        const user = await getMe();
        setData('user', user);
        close();
    }

    return (
        <Form id='timezone-form' onSubmit={handleSubmit}>
            <InputGroup id='timezone'>
                <InputSelect name='timezone' id='timezone' defaultValue={user!.timezone}>
                    {timezones.map((timezone: string) => (
                        <InputSelectOption key={timezone} value={timezone}>
                            {timezone}
                        </InputSelectOption>
                    ))}
                </InputSelect>
            </InputGroup>

            <FormButtonGroup>
                <Button
                    type='submit'
                    disabled={loadingUpdate || loadingMe}
                >Save</Button>
            </FormButtonGroup>
        </Form>
    )
}

function View() {
    const { user } = useData<ServerStateData>();

    return (
        <div>
            {user!.timezone}
        </div>
    )
}

export default function TimezoneSettings() {
    return (
        <SettingsSection
            title='Timezone'
            whenEditing={Edit}
            whenViewing={View}
        />
    )
}