import { useState, forwardRef, ForwardedRef, useRef, useEffect } from 'react';
import './input-text.scss';

export type InputTextProps = {
    id?: string;
    name?: string;
    defaultValue?: string | number;
    hasError?: boolean;
    onChange?: (value: string, id: string) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>;

export type InputTextRef = {
    setValue: (value: string) => void;
    target: HTMLInputElement;
};

export default forwardRef(function InputText({ id, hasError, name, defaultValue, onChange, ...props }: InputTextProps, ref: ForwardedRef<InputTextRef>) {
    const fallbackRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>((ref as any)?.current?.value || (fallbackRef as any)?.current?.value || defaultValue || '');

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        (onChange as any)?.(event.target.value, id);
    }

    return (
        <input
            ref={(refValue: HTMLInputElement) => {
                if (ref) {
                    (ref as any).current = {
                        setValue,
                        target: refValue
                    }
                } else {
                    (fallbackRef as any).current = refValue;
                }

                if (refValue && value !== refValue.value) {
                    handleOnChange({ target: refValue } as any);
                }
            }}
            className={`input-text ${hasError ? 'has-error' : ''}`}
            type={props.type || 'text'}
            id={id}
            name={name || id}
            value={value}
            onChange={handleOnChange}
            {...props}
        />
    )
})