import { cns } from '@/utils/array';
import './button.scss';

export interface ButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    style?: 'custom' | 'gray';
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    className?: string;
}

export default function Button({
    children,
    onClick,
    type = 'button',
    disabled = false,
    style,
    className
}: ButtonProps) {
    const handleClick = () => {
        onClick?.();
    }

    let c = [];
    if (style !== 'custom') {
        c.push('button');
        switch (style) {
            case 'gray':
                c.push('gray');
                break;
            default:
                break;
        }
    }

    if (className) c.push(className);

    return (
        <button className={cns(c)} onClick={handleClick} type={type} disabled={disabled}>
            {children}
        </button>
    )
}