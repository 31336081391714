import { useEffect, useState } from "react";
import { TrackingEntryDay, TrackingEntryMetadata, TrackingEntryStatus } from "../entry/tracking-entry/tracking-entry";
import InputText from "@/components/atoms/input-text/input-text";
import Button from "@/components/atoms/button/button";
import InputCheckbox from "@/components/atoms/input-checkbox/input-checkbox";
import './create-tracking-entry.scss';
import InputGroup from "@/components/atoms/input-group/input-group";
import Label from "@/components/atoms/label/label";

export type CreateTrackingEntryProps = {
    setMetadata: (metadata: TrackingEntryMetadata) => void;
    metadata: TrackingEntryMetadata;
};

export default function CreateTrackingEntry({ setMetadata, metadata }: CreateTrackingEntryProps) {
    const [days, setDays] = useState<TrackingEntryDay[]>(
        metadata?.days && metadata?.days.length > 0 ? metadata.days : 
        [{
            id: "1",
            status: TrackingEntryStatus.Incomplete
        }]
    );

    useEffect(() => {
        setMetadata({ days });
    }, [days, setMetadata]);

    return (
        <div className='create-tracking-form'>
            <InputGroup dir="h">
                <Label>How many times?</Label>
                <InputText
                    type='number'
                    inputMode="numeric"
                    defaultValue={days.length}
                    onChange={(value) => {
                        const newLength = parseInt(value);

                        if (isNaN(newLength) || newLength < 1) {
                            return;
                        }

                        setDays((last) => {
                            const newDays = [...last];
                            if (newLength > newDays.length) {
                                for (let i = newDays.length; i < newLength; i++) {
                                    const id = Math.random().toString(36).substring(7);
                                    newDays.push({
                                        id,
                                        status: TrackingEntryStatus.Incomplete
                                    });
                                }
                            } else if (newLength < newDays.length) {
                                newDays.splice(newLength);
                            }
                            return newDays;
                        })
                    }}
                />
            </InputGroup>

            <div className="tracking-checklist">
                {days.map((day, index) => (
                  <InputCheckbox
                        style='completion'
                        key={index}
                        disabled={true}
                        id={`day-${index}-status`}
                        defaultValue={day.status === TrackingEntryStatus.Complete}
                        onChange={(checked) => {
                            setDays((last) => {
                                const newDays = [...last];
                                newDays[index].status = checked ? TrackingEntryStatus.Complete : TrackingEntryStatus.Incomplete;
                                return newDays;
                            });
                        }}
                    />
                ))}
            </div>
        </div>
    )
};