import { useGetJournalEntriesApi, useGetJournalEntryDatesApi } from "@/api/endpoints";
import Entry from "@/components/molecules/entry/entry";
import { useData } from "@/context/data-context/data-context";
import useEntries from "@/hooks/use-entries";
import { ServerStateData } from "@/main";
import { useEffect, useMemo, useState } from "react";
import SuspenseElement from "@/components/molecules/suspense-element/suspense-element";
import { JournalEntry } from "@/types";
import CreateEntry from "@/components/molecules/create-entry/create-entry";
import './home.scss';
import { MdOutlineCoffeeMaker } from "react-icons/md";
import DayPicker from "@/components/atoms/daypicker/daypicker";
import moment from "moment-timezone";
import Collapsable from "@/components/atoms/collapsable/collapsable";

export default function Home() {
    const [getJournalEntries, { loading }] = useGetJournalEntriesApi();
    const [getJournalEntryDates, { loading: loadingDates }] = useGetJournalEntryDatesApi();
    const { user, entriesDate, entries, entryDates, setData, entryDataError, } = useData<ServerStateData>();
    const { setEntries, setError } = useEntries();
    const [pinnedTitle, setPinnedTitle] = useState<string>('Hide Pinned');

    const pinnedEntries = useMemo(() => entries?.filter((entry) => entry.pinned), [entries]);
    const unpinnedEntries = useMemo(() => entries?.filter((entry) => !entry.pinned), [entries]);

    useEffect(() => {
        if (!entriesDate) {
            setData('entriesDate', 'latest');
        }

        getJournalEntries({
            date: entriesDate || 'latest',
            timezone: user!.timezone,
        }).then(setEntries).catch(setError);

        if (!entryDates) {
            getJournalEntryDates().then((dates) => {
                setData('entryDates', dates);
            }).catch(setError);
        }
    }, [entriesDate]);

    const handlePinnedChange = (pinned: boolean) => {
        if (pinned) {
            setPinnedTitle('Hide Pinned');
        } else {
            setPinnedTitle('Show Pinned');
        }
    }

    if (loading, loadingDates) {
        return null;
    }

    return (
        <>
            <CreateEntry />

            {entryDataError && <div>{entryDataError}</div>}

            <DayPicker />
            <SuspenseElement resolve={entries}>
                {(journalEntries: JournalEntry[]) => {
                    if (journalEntries.length === 0) {
                        return (
                            <div className='no-entries'>
                                <div className='no-entries-icon'>
                                    <MdOutlineCoffeeMaker />
                                </div>
                                <p>
                                    There's nothing here for {
                                        entriesDate === moment().format('YYYY-MM-DD') ? 'today' : moment(entriesDate).format('MMMM Do, YYYY')
                                    }
                                </p>
                            </div>
                        )
                    }

                    return (
                        <>
                            <div className='entries'>
                                {pinnedEntries.length > 0 && (
                                    <Collapsable title={pinnedTitle} open onChange={handlePinnedChange}>
                                        {pinnedEntries?.map((entry) => (
                                            <Entry
                                                key={entry.id}
                                                entry={entry}
                                            />
                                        ))}
                                    </Collapsable>
                                )}

                                {pinnedEntries.length > 0 && unpinnedEntries.length > 0 && ( <hr /> )}

                                {unpinnedEntries?.map((entry) => (
                                    <Entry
                                        key={entry.id}
                                        entry={entry}
                                    />
                                ))}
                            </div>
                        </>
                    )
                }}
            </SuspenseElement>
        </>
    )
}