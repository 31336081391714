import { JournalEntry } from "@/types";
import { EntryProps } from "../entry";
import EntryTemplate from "../entry-template";
import ChecklistEntryItem, { ChecklistItem, ChecklistStatus } from "./checklist-item";
import './checklist-entry.scss';

export interface ChecklistMetadata {
    items: ChecklistItem[];
}

export interface ChecklistEntryProps extends EntryProps {
    updateEntry: (entry: JournalEntry) => void;
}

export default function ChecklistEntry(props: ChecklistEntryProps) {
    let meta = props.entry.metadata as ChecklistMetadata;

    const handleStatusChange = (item: ChecklistItem, status: ChecklistStatus) => {
        const entry = { ...props.entry } as JournalEntry;
        entry.metadata = {
            items: (entry.metadata as ChecklistMetadata).items.map((i) => {
                if (i.text === item.text) {
                    return { ...i, status };
                }
                return i;
            })
        }

        props.updateEntry(entry)
    }

    return <EntryTemplate {...props}>
        {!!meta.items && meta.items.length > 0 && (
            <ul className='checklist-entry-items'>
                {meta.items.map((item) => (
                    <ChecklistEntryItem key={item.text} item={item} handleStatusChange={handleStatusChange} />
                ))}
            </ul>
        )}
    </EntryTemplate>
}