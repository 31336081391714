export interface InputSelectOptionProps extends React.HTMLProps<HTMLOptionElement> {
    children: React.ReactNode;
    value: string;
}

export default function InputSelectOption(props: InputSelectOptionProps) {
    return (
        <option value={props.value}>
            {props.children}
        </option>
    )
}