import { REFRESH_TOKEN_API } from '@/api/endpoints';
import { join } from 'path';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

export type DataProvider = {
    api: {
        get: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;
    };
}

export const api = {
    /**
     * this could use some caching if there are headers present in the response
     * so we can ignore duplicate requests, and just return the existing data.
     * if we go this route, we should also add a way to invalidate the cache.
     */
    get: (input: RequestInfo | URL, init?: RequestInit) => {
        let i = init ? init : {};
        i.method = 'GET';
        return f(input, i);
    },
    post: (input: RequestInfo | URL, init?: RequestInit) => {
        let i = init ? init : {};
        i.method = 'POST';
        return f(input, i);
    },
    delete: (input: RequestInfo | URL, init?: RequestInit) => {
        let i = init ? init : {};
        i.method = 'DELETE';
        return f(input, i);
    },
    put: (input: RequestInfo | URL, init?: RequestInit) => {
        let i = init ? init : {};
        i.method = 'PUT';
        return f(input, i);
    },
    patch: (input: RequestInfo | URL, init?: RequestInit) => {
        let i = init ? init : {};
        i.method = 'PATCH';
        return f(input, i);
    }
}

export class ResponseError extends Error {
    errors: [string, string][] = [];
}

export const DataContext = createContext<unknown>({});

export type DataProviderProps = {
    children: React.ReactNode;
    data: unknown;
    defaultState?: unknown;
};

async function f(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    if (typeof XMLHttpRequest !== 'undefined') {
        try {
            let response = await fetch(input, init);
            if (!response.ok) {
                if (response.status === 401) {
                    try {
                        await REFRESH_TOKEN_API();
    
                        let retryResponse = await f(input, init);
                        if (!retryResponse.ok) {
                            let data = await retryResponse.json();
                            throw data.error;
                        } else {
                            return retryResponse;
                        }
                    } catch (e) {
                        console.error('Failed to refresh token', e);
                        throw e;
                    }
                }

                let data = await response.json();
                throw data.error;
            } else {
                return response;
            }   
        } catch (e) {
            throw e;
        }
    } else {
        return Promise.resolve({ json: () => Promise.resolve({}) } as Response);
    }
}

export type SetData<K> = <T>(key: keyof K, data: T) => void;
export type ResetData = () => void;

export default function DataProvider({ data, defaultState, children }: DataProviderProps) {
    const [serverData, setServerData] = useState({
        ...(defaultState || {}),
        ...(data || {})
    });

    function setData<K, T>(key: keyof K | keyof K[], data: T | T[]) {
        setServerData((prevData: any) => {
            if (Array.isArray(key)) {
                for (let i = 0; i < key.length; i++) {
                    prevData[key[i]] = (data as any)[i];
                }
            } else {
                prevData[key] = data;
            }

            return {
                ...prevData,
            }
        });
    }

    function resetData() {
        setServerData({
            ...(data || {}),
            ...(defaultState || {}),
        });
    }

    const contextValue: DataProvider = useMemo(() => ({
        ...(serverData as object),
        setData,
        resetData,
        api,
    }), [serverData])

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
}

export function useData<T>() {
    const loaderData = useLoaderData() as T;
    const url = useParams();
    const contextData = useContext(DataContext) as unknown as T & DataProvider;

    return {
        ...contextData,
        ...loaderData,
        url
    };
}