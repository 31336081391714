import './badge.scss';

export type BadgeProps = {
    count: number;
}

export default function Badge({ count }: BadgeProps) {
    return (
        <span className="badge">
            {count}
        </span>
    )
}