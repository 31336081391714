import { cns } from "@/utils/array";

export interface LabelProps {
    htmlFor?: string;
    children: React.ReactNode;
    className?: string;
    style?: 'custom';
}

export default function Label({ htmlFor, children, className, style }: LabelProps) {
    let c = [];

    if (style !== 'custom') c.push('input-label');
    if (className) c.push(className);

    return (
        <label className={cns(c)} htmlFor={htmlFor}>
            {children}
        </label>
    )
}