import { useState } from "react";
import './input-checkbox.scss';

export type InputCheckboxProps = {
    id: string;
    name?: string;
    defaultValue: boolean;
    style?: 'custom' | 'completion';
    className?: string;
    label?: string;
    onChange: (checked: boolean) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'defaultValue'>;

export default function InputCheckbox({ id, name, defaultValue, onChange, style, className, label }: InputCheckboxProps) {
    const [value, setValue] = useState<boolean>(defaultValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
        onChange?.(event.target.checked);
    }

    let c = [];
    if (style !== 'custom') c.push('input-checkbox');

    if (className) c.push(className);

    if (style === 'completion') {
        return (
            <div className='input-checkbox-completion'>
                <input
                    type="checkbox"
                    id={id}
                    name={name || id}
                    checked={value}
                    onChange={handleChange}
                    className='completion-checkbox'
                />
                <label className="completion-checkbox-label" htmlFor={id}>
                    <span className='checkbox'></span>
                    {label && <span className='label-text'>{label}</span>}
                </label>
            </div>
        )
    }

    return (
        <input
            type="checkbox"
            id={id}
            name={name || id}
            checked={value}
            onChange={handleChange}
            className={c.join(' ')}
        />
    )
}