import { JournalEntry } from "@/types";
import { EntryProps } from "../entry";
import EntryTemplate from "../entry-template";
import InputCheckbox from "@/components/atoms/input-checkbox/input-checkbox";
import './tracking-entry.scss';

export enum TrackingEntryStatus {
    Complete = "complete",
    Incomplete = "incomplete"
}

export interface TrackingEntryDay {
    id: string;
    status: TrackingEntryStatus;
}

export interface TrackingEntryMetadata {
    days: TrackingEntryDay[];
}


export interface TrackingEntryProps extends EntryProps {
    updateEntry: (entry: JournalEntry) => void;
}

export default function TrackingEntry(props: TrackingEntryProps) {
    let meta = props.entry.metadata as TrackingEntryMetadata;

    const handleStatusChange = (day: TrackingEntryDay, status: TrackingEntryStatus) => {
        const entry = { ...props.entry } as JournalEntry;
        entry.metadata = {
            days: (entry.metadata as TrackingEntryMetadata).days.map((d) => {
                if (d.id === day.id) {
                    return { ...d, status };
                }
                return d;
            })
        }

        props.updateEntry(entry)
    }

    return <EntryTemplate {...props}>
        {!!meta.days && meta.days.length > 0 && (
            <div className='tracking-checklist'>
                {meta.days.map((day, index) => (
                    <InputCheckbox
                        style='completion'
                        key={index}
                        disabled={true}
                        id={`day-${index}-status`}
                        defaultValue={day.status === TrackingEntryStatus.Complete}
                        onChange={(checked) => {
                            handleStatusChange(day, checked ? TrackingEntryStatus.Complete : TrackingEntryStatus.Incomplete);
                        }}
                    />
                ))}
            </div>
        )}
    </EntryTemplate>
}