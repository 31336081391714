import { cns } from '@/utils/array';
import './selling-section.scss';

export interface SellingSectionProps {
    icon: JSX.Element;
    section: string;
    heading: string;
    description: string;
    image: string;
    imageType?: 'video' | 'image';
    blue?: boolean;
}

export default function SellingSection({ section, heading, description, icon, image, blue, imageType }: SellingSectionProps) {
    let c = ['selling-section'];
    if (blue) c.push('blue');

    return (
        <div className={cns(c)}>
            <div className='inner-selling-section'>
                <div className='selling-section-text'>
                    <span>{icon}{section}</span>
                    <h2>
                        {heading}
                    </h2>
                    <p>
                        {description}
                    </p>
                </div>
                <div className='selling-section-image'>
                    {imageType === 'video' && <video src={image} autoPlay loop muted />}
                    {!imageType || imageType === 'image' && <img src={image} /> }
                </div>
            </div>
        </div>
    )
}