import App from "@/App";
import AnonRoute from "@/components/molecules/anon-route/anon-route";
import ErrorElement from "@/components/molecules/error-element/error-element";
import FeedLayout from "@/components/molecules/feed-layout/feed-layout";
import PrivateRoute from "@/components/molecules/private-route/private-route";
import SignLayout from "@/components/molecules/sign-layout/sign-layout";
import Home from "@/pages/home/home";
import ServerError from "@/pages/internal-error/internal-error";
import Landing from "@/pages/landing/landing";
import Login from "@/pages/login/login";
import NotFound from "@/pages/not-found/not-found";
import Register from "@/pages/register/register";
import SearchPage from "@/pages/search/search";
import SettingsPage from "@/pages/settings/settings";
import SingleEntry from "@/pages/single-entry/single-entry";
import path from "path";

let routes = [
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorElement />,
        children: [
            {
                path: '/',
                element: <AnonRoute />,
                children: [
                    {
                        path: '',
                        element: <Landing />
                    },
                    {
                        path: '',
                        element: <SignLayout />,
                        children: [
                            {
                                path: '/login',
                                element: <Login />
                            },
                            {
                                path: '/register',
                                element: <Register />
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        path: '/journal',
        element: <PrivateRoute />,
        children: [
            {
                path: '',
                element: <FeedLayout />,
                children: [
                    {
                        path: '',
                        element: <Home />
                    },
                    {
                        path: 'entry/:id',
                        element: <SingleEntry />
                    }
                ]
            }
        ]
    },
    {
        path: '/search',
        element: <PrivateRoute />,
        children: [
            {
                path: '',
                element: <FeedLayout />,
                children: [
                    {
                        path: '',
                        element: <SearchPage />
                    }
                ]
            }
        ]
    },
    {
        path: '/settings',
        element: <PrivateRoute />,
        children: [
            {
                path: '',
                element: <FeedLayout />,
                children: [
                    {
                        path: '',
                        element: <SettingsPage />
                    }
                ]
            }
        ]
    },
    {
        path: '/server-error',
        element: <ServerError />
    },
    // catchall for notfound
    {
        path: '*',
        element: <NotFound />
    }
]

export default routes;