import moment from 'moment';
import Button from '../button/button';
import './day-picker.scss';
import { useData } from '@/context/data-context/data-context';
import { ServerStateData } from '@/main';
import { cns } from '@/utils/array';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { useRef, useState } from 'react';
import CalendarPicker from '../calendarpicker/calendarpicker';

export default function DayPicker() {
    const [clearDate, setClearDate] = useState<number | null>(null);
    const { setData, entriesDate, entryDates } = useData<ServerStateData>();
    let today = moment();
    const dates = [...new Set((entryDates || []).map((date) => moment.utc(date).local().format('YYYY-MM-DD')))]

    let days = Array.from({ length: 3 }, (_, i) => today.clone().subtract(i + 1, 'days')).reverse();

    let todaySelected = today.format("YYYY-MM-DD") === entriesDate;
    
    let todayClasses = cns([
        'day-picker-day',
        todaySelected ? 'selected' : '',
    ])
    let latestClasses = cns([
        'day-picker-day',
        entriesDate === 'latest' ? 'selected' : '',
    ])

    let daysSelected = [...days].map((day) => day.format("YYYY-MM-DD")).includes(entriesDate);
    let otherDateSelected = !todaySelected && !daysSelected && entriesDate !== 'latest';
    
    let pickerClasses = cns([
        'day-picker-day',
        !todaySelected ? 'no≠t-today' : '',
        otherDateSelected ? 'selected' : '',
    ])

    return (
        <div className='day-picker'>
            <div className='day-picker-days'>
                <div className='date-picker-wrapper'>
                    <CalendarPicker
                        buttonClass={pickerClasses}
                        onClick={(date) => setData('entriesDate', date)}
                        date={entriesDate}
                        clearDate={clearDate}
                    />
                </div>

                {days.map((day) => {
                    let selected = day.format("YYYY-MM-DD") === entriesDate;
                    let dayClasses = cns([
                        'day-picker-day',
                        selected ? 'selected' : '',
                        dates.includes(day.format("YYYY-MM-DD")) ? 'has-entries' : ''
                    ])

                    return (<Button
                            key={day.format()}
                            className={dayClasses}
                            style='custom'
                            onClick={() => {
                                setClearDate(Math.random());
                                setData('entriesDate', day.format("YYYY-MM-DD"))
                            }}
                        >
                            {day.format('Do')}
                        </Button>
                    )
                })}
            </div>
            <Button
                className={todayClasses}
                style='custom'
                onClick={() => {
                    setData('entriesDate', today.format("YYYY-MM-DD"))
                    setClearDate(Math.random());
                }}
            >
                Today
            </Button>
            <Button
                className={latestClasses}
                style='custom'
                onClick={() => {
                    setData('entriesDate', 'latest')
                    setClearDate(Math.random());
                }}
            >
                Latest
            </Button>
        </div>
    )
}