import React, { ForwardedRef, forwardRef, useState } from "react";
import './input-select.scss';

export type InputSelectProps = {
    children: React.ReactNode;
    id?: string;
    defaultValue?: string;
    onChange?: (value: string, id?: string) => void;
} & Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'>;

export type InputSelectRef = {
    setValue: (value: string) => void;
} & HTMLSelectElement;

export default forwardRef(function InputSelect(props: InputSelectProps, ref: ForwardedRef<HTMLSelectElement>) {
    const [value, setValue] = useState<string>(props.defaultValue || "");

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(event.target.value);
        props.onChange?.(event.target.value, props.id);
    }

    return (
        <select
            id={props.id}
            defaultValue={props.defaultValue}
            value={value}
            className="input-select"
            onChange={handleOnChange}
            ref={(refValue) => {
                if (ref) {
                    (ref as any).current = {
                        setValue,
                        ...refValue
                    }
                }

                if (refValue && value !== refValue.value) {
                    handleOnChange({ target: refValue } as any);
                }
            }}
        >
            {props.children}
        </select>
    )
});