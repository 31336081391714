export enum EventAction {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}

export enum EventSubject {
    JournalEntry = 'journal-entry',
}

export type EventDetails = {
    action: EventAction;
    subject: EventSubject;
    meta: object;
}

export function useUmami() {
    return {
        trackEvent: (event: EventDetails) => {
            if (typeof window !== 'undefined' && (window as any).umami) {
                const eventString = [
                    `[${event.action.toUpperCase()}]`,
                    event.subject,
                ].join(' ');

                (window as any).umami.track(eventString, event.meta);
            }
        }
    }
}