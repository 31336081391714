import { ApiErrorResponse, ApiErrorType } from "@/types";
import { Children, cloneElement, ReactNode, Ref, RefObject, useCallback, useRef } from "react";
import Alert from "../alert/alert";
import './form.scss';

export interface FormProps<T> {
    id?: string;
    children: React.ReactNode;
    onSubmit?: (data: T) => void;
    onChange?: (data: T) => void;
    error?: ApiErrorResponse | null;
    errorOn?: 'all';
}

const DONT_PROCESS_CHILDREN = ['FormButtonGroup', 'InputSelect', 'InputText', 'InputMultiline'];

function processChildren<T>(
    children: React.ReactNode,
    parent: React.ReactElement | null,
    handleInputChange: any,
    temp_data: RefObject<T>
): React.ReactNode {
    return Children.toArray(children).map((child: any): any => {
        if (!child.type) return child;

        const typeName: string = child.type.name || child.type.render?.name || '';
        const shouldProcess = DONT_PROCESS_CHILDREN.filter((name) => typeName.startsWith(name)).length === 0;

        if (shouldProcess && child.props.children) {
            const processedChildren = processChildren(child.props.children, child, handleInputChange, temp_data);
            return cloneElement(child, { ...child.props }, processedChildren);
        } else if (typeName.startsWith('InputText') || typeName.startsWith('InputMultiline') || typeName.startsWith('InputSelect')) {
            const id = parent?.props?.id || '';

            if (id && !(temp_data.current as T)[id as keyof T]) {
                (temp_data.current as T)[id as keyof T] = child.props.defaultValue || child.props.value || ''
            }

            let childProps = { ...child.prop, id };
            if (typeof child.props.onChange !== 'function') {
                childProps.onChange = handleInputChange;
            }

            return cloneElement(child, childProps);
        }
        return child;
    });
};

function InnerForm<T>({
    children,
    error,
    errorOn,
    id,
    values,
    handleSubmit,
    handleInputChange,
}: FormProps<T> & {
    values: RefObject<T>,
    handleSubmit: (e: any) => void,
    handleInputChange: (value: string, id: string) => void
}) {    
    let c = useRef<ReactNode>(null);

    c.current = processChildren<T>(children, null, handleInputChange, values);

    return (
        <form id={id} noValidate className='form' onSubmit={handleSubmit}>
            {error && (errorOn === 'all' || error.error_type !== ApiErrorType.Validation) && <Alert type='error'>{error.message}</Alert>}
            {c.current}
        </form>
    )
}

export default function Form<T extends object>(props: FormProps<T>) {
    let values = useRef<T>({} as T);

    const handleSubmit = useCallback((e: any) => {
        e.preventDefault();
        props?.onSubmit?.(values.current as T);
    }, [props]);

    const handleInputChange = useCallback((value: string, id: string) => {
        if (!values.current) {
            values.current = {} as T;
        }

        values.current = {
            ...values.current,
            [id]: value
        };
    }, []);

    // this is really weird, but it works.
    // so this is how it is.
    let form = InnerForm({
        ...props,
        values,
        handleSubmit,
        handleInputChange,
    });

    return form;
}