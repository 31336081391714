import Button from "@/components/atoms/button/button";
import { useState } from "react";
import './settings-section.scss';

export type SettingsSectionProps = {
    title: string;
    whenEditing: (_: SettingsSectionEditProps) => JSX.Element;
    whenViewing: () => JSX.Element;
}

export type SettingsSectionEditProps = {
    close: () => void;
}

export default function SettingsSection(props: SettingsSectionProps) {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div className='settings-section'>
            <div className='settings-section-title'>
                {props.title}

                <Button
                    type='button'
                    onClick={() => setIsEditing(!isEditing)}
                >
                    {isEditing ? 'Cancel' : 'Edit'}
                </Button>
            </div>

            {isEditing ? (
                <div className='settings-section-editing'>
                    <props.whenEditing
                        close={() => setIsEditing(false)}
                    />
                </div>
            ) : (
                <div className='settings-section-viewing'>
                    <props.whenViewing />
                </div>
            )}
        </div>
    )
}