import { createContext, useContext, useMemo, useState } from 'react';
import { useData } from '@/context/data-context/data-context';
import { ServerStateData } from '@/main';
import { matchRoutes, useLocation, useRoutes } from 'react-router-dom';
import PageProps from '@/types/page-props';
import useTitle from '@/hooks/use-title';
import routes from '@/router/routes';

export type PageLayoutProvider = {
    header?: string | null;
    setHeader: (header: string | null) => void;
}

export const PageLayoutContext = createContext<unknown>({});

export type PageLayoutProviderProps = {
    children: React.ReactNode;
};

export default function PageLayoutProvider({ children }: PageLayoutProviderProps) {
    const { header: pageHeader } = useData<ServerStateData>();
    const [header, setHeader] = useState<string | null | undefined>(pageHeader);

    const contextValue: PageLayoutProvider = useMemo(() => ({
        header,
        setHeader,
    }), [header])

    return (
        <PageLayoutContext.Provider value={contextValue}>
            {children}
        </PageLayoutContext.Provider>
    );
}

export function usePage() {
    const contextData = useContext(PageLayoutContext) as PageLayoutProvider;
    const location = useLocation();
    let path = matchRoutes(routes, location)?.reduce((acc, { route }) => {
        if (!route.path) return acc;
        acc.push(route.path)
        return acc;
    }, [] as string[]).filter(Boolean).join('/').replace(/\/+/g, '/');

    if (!path) {
        path = location.pathname;
    }

    let page = PageProps[path as keyof typeof PageProps];
    
    contextData?.setHeader?.(page?.header);
    useTitle(page?.title, path === '/');

    useMemo(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return {
        ...contextData,
    };
}