import { useRegisterUserApi } from "@/api/endpoints";
import Button from "@/components/atoms/button/button";
import FormButtonGroup from "@/components/atoms/form-button-group/form-button-group";
import Form from "@/components/atoms/form/form";
import InputGroup from "@/components/atoms/input-group/input-group";
import InputText from "@/components/atoms/input-text/input-text";
import Label from "@/components/atoms/label/label";
import useForm from "@/hooks/use-form";
import { ApiErrorResponse, RegisterUserPayload } from "@/types";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';

interface RegisterFormData {
    name: string;
    email: string;
    password: string;
}

export default function Register() {
    const [registerUser, { loading }] = useRegisterUserApi();
    const { formError, setFormError } = useForm();
    const navigate = useNavigate();

    const handleRegister = (data: RegisterFormData) => {
        let nameParts = data.name.split(' ');
        let firstName = nameParts[0];
        let lastName = nameParts[1] || null;

        let payload: RegisterUserPayload = {
            email: data.email,
            password: data.password,
            first_name: firstName,
            last_name: lastName,
            timezone: typeof window !== 'undefined' ? moment.tz.guess() : 'UTC',
        }

        registerUser(payload)
            .then(() => {
                navigate('/login?registered=true');
            })
            .catch((error: ApiErrorResponse) => {
                setFormError(error);
            });
    }

    return (
        <>
            <div className='sign-layout-form'>
                <Form id='register-form' onSubmit={handleRegister} error={formError}>
                    <InputGroup error={formError} errorFields={['first_name', 'last_name']} id='name'>
                        <Label>Name</Label>
                        <InputText />
                    </InputGroup>

                    <InputGroup error={formError} id='email'>
                        <Label>Email</Label>
                        <InputText type='email' autoComplete="email" />
                    </InputGroup>

                    <InputGroup error={formError} id='password'>
                        <Label>Password</Label>
                        <InputText type='password' autoComplete="new-password" />
                    </InputGroup>

                    <FormButtonGroup>
                        <Button type='submit' disabled={loading}>Register</Button>
                    </FormButtonGroup>
                </Form>
            </div>
            <div>
                <p>Already have an account? <Link className='sign-link' to='/login'>Login</Link></p>
            </div>
        </>
    )
}