import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";

export default function ServerError() {
    const { server_error } = useData<ServerStateData>();

    return (
        <div>
            <h1>uh oh...</h1>
            <p>Sorry, the server is having issues.</p>
            <p>Specifically: {server_error}</p>
        </div>
    )
}