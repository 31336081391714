import { Link, Outlet, useLocation } from "react-router-dom";
import './feed-layout.scss';
import { MdOutlineNewspaper, MdPerson, MdSearch } from "react-icons/md";
import { usePage } from "../page-layout/page-layout-context";

export default function FeedLayout() {
    const location = useLocation();
    usePage();

    return (
        <div className="feed-layout-wrapper">
            <div className="feed-layout-page">
                <div className="navigation">
                    <ul>
                        <li>
                            <Link className={`${location.pathname === '/journal' ? 'active' : ''}`} to="/journal">
                                <MdOutlineNewspaper />
                            </Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname === '/search' ? 'active' : ''}`} to="/search">
                                <MdSearch />
                            </Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname === '/settings' ? 'active' : ''}`} to="/settings">
                                <MdPerson />
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="feed-layout">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}