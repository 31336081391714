import { JournalEntry } from "@/types";
import { EntryProps } from "./entry";
import EntryTemplate from "./entry-template";

export interface BasicEntryProps extends EntryProps {
    updateEntry: (entry: JournalEntry) => void;
}

export default function BasicEntry(props: BasicEntryProps) {
    return <EntryTemplate {...props} />
}