export enum ApiErrorType {
    Validation = 'Validation',
    Request = 'Request',
    Requirement = 'Requirement',
    Internal = 'Internal',
    Authentication = 'Authentication',
    Authorization = 'Authorization',
}

export enum EntryType {
    Basic = 'Basic',
    Checklist = 'Checklist',
    Tracking = 'Tracking',
}

export type LoginPayload = {
    email: string;
    password: string;
}

export type ApiErrorResponse = {
    message: string;
    error_type: ApiErrorType;
    field: string | null;
}

export type PublicUser = {
    id: string;
    email: string;
    first_name: string;
    last_name: string | null;
    timezone: string;
    created_at: string;
    formatted_created_at: string;
}

export type RegisterUserPayload = {
    email: string;
    password: string;
    first_name: string;
    last_name: string | null;
    timezone: string;
}

export type UpdateMePayload = {
    first_name: string | null;
    last_name: string | null;
    timezone: string | null;
}

export type NavigationLink = {
    path: string;
    label: string;
    prereq: string | null;
}

export type ApiResponse<T> = {
    success: boolean;
    data: T | null;
    error: ApiErrorResponse | null;
}

export type CreateJournalEntryPayload = {
    content: string;
    metadata: Object;
    entry_type: EntryType;
    parent_id: string | null;
}

export type UpdateJournalEntryPayload = {
    content: string;
    metadata: Object;
    pinned: boolean;
    entry_type: EntryType;
}

export type SearchJournalEntriesQuery = {
    query: string;
}

export type GetJournalEntriesQuery = {
    date: string;
    timezone: string;
}

export type JournalEntry = {
    id: string;
    content: string;
    metadata: Object;
    entry_type: EntryType;
    parent_id: string | null;
    pinned: boolean;
    created_at: string;
    formatted_created_at: string;
    updated_at: string | null;
    formatted_updated_at: string | null;
    thread_count: number;
}

export type JournalEntryPreview = {
    id: string;
    content: string;
    title: string;
    entry_type: EntryType;
    created_at: string;
    formatted_created_at: string;
    thread_count: number;
}

export type Tag = {
    id: string;
    name: string;
    created_at: string;
    updated_at: string | null;
}

