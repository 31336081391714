import { useState } from "react";
import { MdChevronLeft } from "react-icons/md";
import Button from "../button/button";
import './collapsable.scss'
import { cns } from "@/utils/array";

export type CollapsableProps = {
    children?: React.ReactNode;
    title: string,
    open?: boolean;
    onChange?: (open: boolean) => void;
}

export default function Collapsable(props: CollapsableProps) {
    const [isOpen, setIsOpen] = useState(props.open || false);

    const handleChange = () => {
        setIsOpen(!isOpen);
        props.onChange?.(!isOpen);
    }

    return (
        <div className='collapsable'>
            <Button
                style='custom'
                className={cns([
                    'collapsable-title',
                    isOpen ? 'open' : ''
                ])}
                onClick={handleChange}
            >
                <span>{props.title}</span>
                <MdChevronLeft color="#1d4775" />
            </Button>

            {isOpen && props.children}
        </div>
    )
}