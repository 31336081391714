import { useDeleteJournalEntryApi, useGetJournalEntriesApiBackground } from "@/api/endpoints";
import { EntryProps } from "./entry";
import Button from "@/components/atoms/button/button";
import { JournalEntry } from "@/types";
import { useState } from "react";
import useEntries from "@/hooks/use-entries";
import CreateEntry from "../create-entry/create-entry";
import { MdAdd, MdLink, MdOpenInNew, MdOutlineDelete, MdOutlineEdit, MdOutlinePushPin, MdOutlineVisibility } from "react-icons/md";
import EntryThread from "./entry-thread";
import { cns } from "@/utils/array";
import Link from "@/components/atoms/link/link";
import { ServerStateData } from "@/main";
import { useData } from "@/context/data-context/data-context";
import EntryPreview from "@/components/atoms/entry-preview/entry-preview";
import { formatDate } from "@/utils/format";

export interface EntryTemplateProps extends EntryProps {
    children?: React.ReactNode;
    updateEntry: (entry: JournalEntry) => void;
};

export default function EntryTemplate({
    entry,
    children,
    updateEntry,
    fromThread,
    fromSearch,
    afterUpdate,
    entryThread,
}: EntryTemplateProps) {
    const [getJournalEntriesBackground] = useGetJournalEntriesApiBackground();
    const [deleteJournalEntry] = useDeleteJournalEntryApi();
    const [isEditing, setIsEditing] = useState(false);
    const { setEntries, setError } = useEntries();
    const [creatingThread, setCreatingThread] = useState(false);
    const [threadCount, setThreadCount] = useState(entry.thread_count);
    const { entriesDate, user } = useData<ServerStateData>();
    const [threadEntries, setThreadEntries] = useState<JournalEntry[]>(entryThread || []);

    const shouldShowThread = (threadCount > 0 || creatingThread || threadEntries.length > 0) && !fromThread && !fromSearch;

    const deleteEntry = (id: string) => () => {
        deleteJournalEntry(id).then(() => {
            if (afterUpdate) {
                afterUpdate();
            } else {
                getJournalEntriesBackground({
                    date: entriesDate,
                    timezone: user!.timezone,
                }).then(setEntries).catch(setError)
            }
        })
    }

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    }

    const pinEntry = () => {
        const updatedEntry = { ...entry } as JournalEntry;
        updatedEntry.pinned = !updatedEntry.pinned;

        updateEntry(updatedEntry);
    }

    const entryTags = (() => {
        const tags = {
            inline: [] as string[],
            block: [] as string[],
            non: [] as string[]
        }
        let inTag: 'inline' | 'block' | 'non' | null = null;
        let lastChar = '';
        let tag = '';
    
        for (const c of entry.content) {
            if (c === '#' && (lastChar === ' ' || lastChar === '\n' || lastChar === '')) {
                inTag = 'block';
                tag = "#";
            } else if (c === '#' && lastChar === '#') {
                inTag = 'non';
                tag = '|##|';
            } else if (c === '#' && lastChar === '-') {
                inTag = 'inline';
                tag = '|--|';

            } else if (c === ' ' || c === '\n' || c.match(/[^a-zA-Z0-9]/)) {
                if (inTag === 'block') {
                    tags.block.push(tag);
                } else if (inTag === 'non') {
                    tags.non.push(tag);
                } else if (inTag === 'inline') {
                    tags.inline.push(tag);
                }

                tag = '';
                inTag = null;
            } else if (inTag !== null) {
                tag += c;
            }

            lastChar = c;
        }
    
        if (inTag === 'block') {
            tags.block.push(tag);
        } else if (inTag === 'non') {
            tags.non.push(tag);
        } else if (inTag === 'inline') {
            tags.inline.push(tag);
        }
    
        return tags;
    })();

    const entryContent = () => {
        let content = entry.content;
        content = content.replace(/-#/g, '|--|').replace(/##/g, '|##|')

        for (const tag of entryTags.block) {
            content = content.replace(tag, '');
        }

        for (const nonTag of entryTags.non) {
            content = content.replace(nonTag, nonTag.replace('|##|', '#'));
        }

        const contentElements = [];
        for (const inlineTag of entryTags.inline) {
            const tag = inlineTag.replace(/\|--\|/g, '#').trim()
            const index = content.indexOf(inlineTag);
            const before = content.slice(0, index);
            const after = content.slice(index + inlineTag.length);

            contentElements.push(before);

            contentElements.push(
                <Link
                    key={inlineTag}
                    className='entry-tag'
                    to={`/search?q=${encodeURIComponent(tag)}`}
                >
                    {tag}
                </Link>
            );

            content = after;
        }

        // find links in content
        const linkRegex = /(?:https?):\/\/[\S]+/g;
        const links = content.match(linkRegex);

        if (links) {
            links.forEach(link => {

                const index = content.indexOf(link);
                const before = content.slice(0, index);
                let after = content.slice(index + link.length);

                contentElements.push(before);


                if (link.startsWith(`${import.meta.env.VITE_CANONICAL_URL}/journal/entry`)) {
                    const entryId = link.split('/').pop();
                    if (!entryId) {
                        contentElements.push(
                            <Link
                                key={link + Math.random()}
                                to={link}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {link}
                            </Link>
                        );
                    } else {
                        contentElements.push(
                            <EntryPreview
                                key={link + Math.random()}
                                entryId={entryId}
                            />
                        );
                    }
                } else {
                    contentElements.push(
                        <Link
                            key={link + Math.random()}
                            to={link}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {link}
                        </Link>
                    );
                }

                content = after;
            });
        }

        return (
            <div>
                {contentElements}
                {content}
            </div>
        )
    };

    const wrapperClasses = cns([
        'entry-wrapper',
        shouldShowThread ? 'with-thread' : ''
    ])

    return (
        <div className={wrapperClasses}>
            <div className="entry">
                <div className="entry-title">
                    <div className='entry-details'>
                        {entry.parent_id !== null && fromSearch && (
                            <div className="entry-from-thread">
                                Thread
                            </div>
                        )}
                        <div className="entry-date">
                            {formatDate(entry.created_at, user!.timezone)}
                        </div>
                    </div>
                    <div className="entry-actions">
                        {
                            !isEditing && (
                                <>
                                    {threadCount === 0 && !fromThread && !fromSearch && entry.parent_id === null && (
                                        <Button
                                            type="button"
                                            style="custom"
                                            className='entry-action-button'
                                            onClick={() => setCreatingThread(true)}
                                        >
                                            <MdAdd />
                                        </Button>
                                    )}
                                    <Link
                                        className='entry-action-button'
                                        to={`/journal/entry/${entry.id}`}
                                    >
                                        <MdOpenInNew />
                                    </Link>
                                    <Button
                                        type="button"
                                        style="custom"
                                        className={`entry-action-button ${entry.pinned ? 'active' : ''}`}
                                        onClick={pinEntry}
                                    >
                                        {entry.pinned ? <MdOutlinePushPin /> : <MdOutlinePushPin />}
                                    </Button>
                                    <Button
                                        type="button"
                                        style="custom"
                                        className='entry-action-button'
                                        onClick={toggleEditMode}
                                    >
                                        <MdOutlineEdit />
                                    </Button>
                                    <Button
                                        type="button"
                                        style="custom"
                                        className='entry-action-button'
                                        onClick={deleteEntry(entry.id)}
                                    >
                                        <MdOutlineDelete />
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="entry-content">
                    {isEditing ? (
                        <CreateEntry
                            afterUpdate={afterUpdate}
                            editing={true}
                            setIsEditing={setIsEditing}
                            entry={entry}
                        />
                    ) : (
                        <>
                            {entryContent()}
                            {entryTags.block.length > 0 && (
                                <div className='entry-tags'>
                                    {entryTags.block.map((tag, i) => (
                                        <Link
                                            key={i}
                                            className='entry-tag'
                                            to={`/search?q=${encodeURIComponent(tag.trim())}`}
                                        >
                                            {tag.trim()}
                                        </Link>
                                    ))}
                                </div>
                            )}
                            {!!children && (
                                <div>
                                    {children}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {shouldShowThread && (
                <EntryThread
                    entry={entry}
                    thread={threadEntries}
                    setThread={setThreadEntries}
                    threadCount={threadCount}
                    setThreadCount={setThreadCount}
                    creatingThread={creatingThread}
                    setCreatingThread={setCreatingThread}
                />
            )}
        </div>
    )
}