import { usePage } from '../page-layout/page-layout-context';
import './landing-layout.scss';

export interface LandingLayoutProps {
    children: React.ReactNode;
}

export default function LandingLayout({ children }: LandingLayoutProps) {
    usePage();
    return (
        <div className='landing'>
            {children}
        </div>
    )
}