import { useGetMeApi, useLoginApi } from "@/api/endpoints";
import Alert from "@/components/atoms/alert/alert";
import Button from "@/components/atoms/button/button";
import FormButtonGroup from "@/components/atoms/form-button-group/form-button-group";
import Form from "@/components/atoms/form/form";
import InputGroup from "@/components/atoms/input-group/input-group";
import InputText from "@/components/atoms/input-text/input-text";
import Label from "@/components/atoms/label/label";
import { useData } from "@/context/data-context/data-context";
import useForm from "@/hooks/use-form";
import { ServerStateData } from "@/main";
import { ApiErrorResponse, LoginPayload } from "@/types";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Login() {
    const [login, { loading }] = useLoginApi();
    const [getMe] = useGetMeApi();
    const { setData } = useData<ServerStateData>();
    const { formError, setFormError } = useForm();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const fromRegister = Boolean(query.get('registered'));
    
    const handleLogin = (data: LoginPayload) => {
        login(data).then(() => {
            getMe().then((me) => {
                setData('user', me)
            })
        }).catch((error: ApiErrorResponse) => {
            setFormError(error)
        })
    }

    return (
        <>
            <div className='sign-layout-form'>
                {fromRegister && <Alert type='success'>You have been registered successfully. Please login.</Alert>}
                <Form id='login-form' onSubmit={handleLogin} error={formError}>
                    <InputGroup id='email' error={formError}>
                        <Label>Email</Label>
                        <InputText type='email' autoComplete="email" />
                    </InputGroup>

                    <InputGroup id='password' error={formError}>
                        <Label>Password</Label>
                        <InputText type='password' autoComplete="current-password" />
                    </InputGroup>
                    
                    <FormButtonGroup>
                        <Button type='submit' disabled={loading}>Login</Button>
                    </FormButtonGroup>
                </Form>
            </div>
            <div>
                <p>Don't have an account? <Link className='sign-link' to='/register'>Register</Link></p>
            </div>
        </>
    )
}