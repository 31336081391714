import { useLogoutApi } from "@/api/endpoints";
import Button from "@/components/atoms/button/button";
import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";
import TimezoneSettings from "./sections/timezone";
import './settings.scss';
import NameSettings from "./sections/name";

export default function SettingsPage() {
    const { user, resetData } = useData<ServerStateData>();
    const [logout] = useLogoutApi();
    
    return (
        <div className='settings'>
            <div className='profile'>
                <div className='name'>
                    <span>
                        {`${[user!.first_name, user!.last_name].filter(Boolean).join(' ')}`}
                    </span>

                    <Button
                        type='button'
                        onClick={(() => logout()
                            .then(() => {
                                resetData()
                            }))}
                    >
                        Logout
                    </Button>
                </div>
                <div>
                    {user!.email}
                </div>
            </div>

            <div className='settings-blocks'>
                <NameSettings />
                <TimezoneSettings />
            </div>
        </div>
    )
}