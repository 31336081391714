import { useGetJournalEntriesApi, useLogoutApi, useSearchJournalEntriesApi } from "@/api/endpoints";
import Button from "@/components/atoms/button/button";
import InputText, { InputTextRef } from "@/components/atoms/input-text/input-text";
import { useData } from "@/context/data-context/data-context";
import useEntries from "@/hooks/use-entries";
import { ServerStateData } from "@/main";
import { useEffect, useRef } from "react";
import Form from "@/components/atoms/form/form";
import InputGroup from "@/components/atoms/input-group/input-group";
import FormButtonGroup from "@/components/atoms/form-button-group/form-button-group";
import SuspenseElement from "@/components/molecules/suspense-element/suspense-element";
import { JournalEntry } from "@/types";
import Entry from "@/components/molecules/entry/entry";
import useSearch from "@/hooks/use-search";
import { EventAction, EventSubject, useUmami } from "@/hooks/use-umami";

export default function SearchPage() {
    const searchInputRef = useRef<InputTextRef>(null);
    const [getJournalEntries, { loading }] = useGetJournalEntriesApi();
    const [searchJournalEntries, { loading: searchLoading }] = useSearchJournalEntriesApi();
    const { searchEntries, user, setData, entryDataError } = useData<ServerStateData>();
    const { setEntries, setError } = useSearch();
    const { trackEvent } = useUmami();

    const handleSearch = (data: { search: string }) => {
        if (!data.search) return;

        window.history.pushState({}, "", `?q=${encodeURIComponent(data.search)}`);

        trackEvent({
            action: EventAction.Create,
            subject: EventSubject.JournalEntry,
            meta: { }
        });

        search(data.search)
    }

    const refetchEntries = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const query = decodeURIComponent(urlParams.get('q') || "");

        if (query) {
            search(query);
            return true;
        }

        return false;
    }

    const search = (query: string) => {
        searchJournalEntries({
            query,
        })
        .then(setEntries)
        .catch(setError);
    }

    const clearSearch = () => {
        searchInputRef.current?.setValue("");
        setEntries([]);

        window.history.pushState({}, "", "/search");
    }

    useEffect(() => {
        let fetched = refetchEntries();
        if (!fetched) {
            setEntries([]);
        }
    }, [])
    
    return (
        <>
            <Form onSubmit={handleSearch} id={"search-form"} error={null}>
                <InputGroup id='search' error={null}>
                    <InputText
                        ref={searchInputRef}
                        placeholder="Search by tag or entry content"
                        defaultValue={typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('q') || "" : ""}
                        autoComplete="off"
                    />
                </InputGroup>
                <FormButtonGroup>
                    <Button type="submit">Search</Button>
                    {searchEntries?.length > 0 && (
                        <Button type="button" style="gray" onClick={clearSearch}>Clear</Button>
                    )}
                </FormButtonGroup>
            </Form>

            <SuspenseElement resolve={searchEntries}>
                {(journalEntries: JournalEntry[]) => {
                    return (
                        <div className='entries'>
                            {journalEntries?.map((entry) => (
                                <Entry
                                    key={entry.id}
                                    entry={entry}
                                    fromSearch={true}
                                    afterUpdate={() => refetchEntries()}
                                />
                            ))}
                        </div>
                    )
                }}
            </SuspenseElement>
        </>
    )
}