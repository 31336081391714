import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";

export default function useTitle(title?: string | null, omitAppName?: boolean) {
    if (typeof document === 'undefined') return;

    let docTitle = [];

    let { APP_NAME } = useData<ServerStateData>();
    if (!title) docTitle.push(APP_NAME)
    else {
        docTitle.push(title);
        if (!omitAppName) docTitle.push(APP_NAME);
    }


    document.title = docTitle.filter(Boolean).join(' | ');
}