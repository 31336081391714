import { Link } from 'react-router-dom';
import useTitle from '@/hooks/use-title';

export default function NotFound() {
    useTitle('Not found!!!!1');

    return (
        <div>
            <h1>Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <Link to="/">Go back to the homepage</Link>
        </div>
    )
}