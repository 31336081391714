import { Suspense } from "react";
import { Await, AwaitProps } from "react-router-dom";
import ErrorElement from "../error-element/error-element";

type SuspenseElementProps = {
    resolve: AwaitProps['resolve'],
    children?: AwaitProps['children']
}

export default function SuspenseElement({
    resolve,
    children
}: SuspenseElementProps) {
    return (
        <Suspense fallback={<h1>Loading...</h1>}>
            <Await
                resolve={resolve}
                errorElement={<ErrorElement />}
            >
                {children}
            </Await>
        </Suspense>
    )
}