import { api } from "@/context/data-context/data-context"
import { useState, useCallback, Dispatch, SetStateAction } from "react"
import {
    ApiResponse,
    LoginPayload,
    PublicUser,
    RegisterUserPayload,
    UpdateMePayload,
    JournalEntry,
    GetJournalEntriesQuery,
    SearchJournalEntriesQuery,
    JournalEntryPreview,
    CreateJournalEntryPayload,
    UpdateJournalEntryPayload
} from "@/types"

export const LOGIN_API = (payload: LoginPayload) =>
    api.post(`/api/login`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useLoginApi = (): [(payload: LoginPayload) => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback((payload: LoginPayload) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.post(`/api/login`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useLoginApiBackground = (): [(payload: LoginPayload) => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((payload: LoginPayload) => {
                    setError(null);
            
                    return api.post(`/api/login`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const LOGOUT_API = () =>
    api.post(`/api/logout`)
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useLogoutApi = (): [() => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback(() => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.post(`/api/logout`)
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useLogoutApiBackground = (): [() => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback(() => {
                    setError(null);
            
                    return api.post(`/api/logout`)
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const REFRESH_TOKEN_API = () =>
    api.post(`/api/refresh-token`)
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useRefreshTokenApi = (): [() => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback(() => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.post(`/api/refresh-token`)
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useRefreshTokenApiBackground = (): [() => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback(() => {
                    setError(null);
            
                    return api.post(`/api/refresh-token`)
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const GET_ME_API = () =>
    api.get(`/api/me`)
    .then((res) => res.json() as Promise<ApiResponse<PublicUser>>)
    .then((res) => {
        if (res.success) {
            return res.data as PublicUser
        } else {
            throw res.error
        }
    })

export const useGetMeApi = (): [() => Promise<PublicUser>, { data: PublicUser | null, setData: Dispatch<SetStateAction<PublicUser | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<PublicUser | null>(null)

    const request = useCallback(() => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/me`)
                .then((res) => res.json() as Promise<ApiResponse<PublicUser>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as PublicUser);
                        return res.data as PublicUser
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useGetMeApiBackground = (): [() => Promise<PublicUser>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback(() => {
                    setError(null);
            
                    return api.get(`/api/me`)
                        .then((res) => res.json() as Promise<ApiResponse<PublicUser>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as PublicUser
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const REGISTER_USER_API = (payload: RegisterUserPayload) =>
    api.post(`/api/user/register`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useRegisterUserApi = (): [(payload: RegisterUserPayload) => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback((payload: RegisterUserPayload) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.post(`/api/user/register`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useRegisterUserApiBackground = (): [(payload: RegisterUserPayload) => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((payload: RegisterUserPayload) => {
                    setError(null);
            
                    return api.post(`/api/user/register`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const UPDATE_ME_API = (payload: UpdateMePayload) =>
    api.patch(`/api/me`, {
                    method: "PATCH",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
    .then((res) => res.json() as Promise<ApiResponse<boolean>>)
    .then((res) => {
        if (res.success) {
            return res.data as boolean
        } else {
            throw res.error
        }
    })

export const useUpdateMeApi = (): [(payload: UpdateMePayload) => Promise<boolean>, { data: boolean | null, setData: Dispatch<SetStateAction<boolean | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<boolean | null>(null)

    const request = useCallback((payload: UpdateMePayload) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.patch(`/api/me`, {
                    method: "PATCH",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then((res) => res.json() as Promise<ApiResponse<boolean>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as boolean);
                        return res.data as boolean
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useUpdateMeApiBackground = (): [(payload: UpdateMePayload) => Promise<boolean>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((payload: UpdateMePayload) => {
                    setError(null);
            
                    return api.patch(`/api/me`, {
                    method: "PATCH",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                        .then((res) => res.json() as Promise<ApiResponse<boolean>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as boolean
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const GET_JOURNAL_ENTRIES_API = (query: GetJournalEntriesQuery) =>
    api.get(`/api/journal/entries?${new URLSearchParams(query).toString()}`)
    .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
    .then((res) => {
        if (res.success) {
            return res.data as JournalEntry[]
        } else {
            throw res.error
        }
    })

export const useGetJournalEntriesApi = (): [(query: GetJournalEntriesQuery) => Promise<JournalEntry[]>, { data: JournalEntry[] | null, setData: Dispatch<SetStateAction<JournalEntry[] | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<JournalEntry[] | null>(null)

    const request = useCallback((query: GetJournalEntriesQuery) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/journal/entries?${new URLSearchParams(query).toString()}`)
                .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as JournalEntry[]);
                        return res.data as JournalEntry[]
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useGetJournalEntriesApiBackground = (): [(query: GetJournalEntriesQuery) => Promise<JournalEntry[]>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((query: GetJournalEntriesQuery) => {
                    setError(null);
            
                    return api.get(`/api/journal/entries?${new URLSearchParams(query).toString()}`)
                        .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as JournalEntry[]
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const GET_ENTRY_THREAD_API = (id: string) =>
    api.get(`/api/journal/entry/${id}/thread`)
    .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
    .then((res) => {
        if (res.success) {
            return res.data as JournalEntry[]
        } else {
            throw res.error
        }
    })

export const useGetEntryThreadApi = (): [(id: string) => Promise<JournalEntry[]>, { data: JournalEntry[] | null, setData: Dispatch<SetStateAction<JournalEntry[] | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<JournalEntry[] | null>(null)

    const request = useCallback((id: string) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/journal/entry/${id}/thread`)
                .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as JournalEntry[]);
                        return res.data as JournalEntry[]
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useGetEntryThreadApiBackground = (): [(id: string) => Promise<JournalEntry[]>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((id: string) => {
                    setError(null);
            
                    return api.get(`/api/journal/entry/${id}/thread`)
                        .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as JournalEntry[]
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const SEARCH_JOURNAL_ENTRIES_API = (query: SearchJournalEntriesQuery) =>
    api.get(`/api/journal/entries/search?${new URLSearchParams(query).toString()}`)
    .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
    .then((res) => {
        if (res.success) {
            return res.data as JournalEntry[]
        } else {
            throw res.error
        }
    })

export const useSearchJournalEntriesApi = (): [(query: SearchJournalEntriesQuery) => Promise<JournalEntry[]>, { data: JournalEntry[] | null, setData: Dispatch<SetStateAction<JournalEntry[] | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<JournalEntry[] | null>(null)

    const request = useCallback((query: SearchJournalEntriesQuery) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/journal/entries/search?${new URLSearchParams(query).toString()}`)
                .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as JournalEntry[]);
                        return res.data as JournalEntry[]
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useSearchJournalEntriesApiBackground = (): [(query: SearchJournalEntriesQuery) => Promise<JournalEntry[]>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((query: SearchJournalEntriesQuery) => {
                    setError(null);
            
                    return api.get(`/api/journal/entries/search?${new URLSearchParams(query).toString()}`)
                        .then((res) => res.json() as Promise<ApiResponse<JournalEntry[]>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as JournalEntry[]
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const GET_JOURNAL_ENTRY_DATES_API = () =>
    api.get(`/api/journal/entries/dates`)
    .then((res) => res.json() as Promise<ApiResponse<string[]>>)
    .then((res) => {
        if (res.success) {
            return res.data as string[]
        } else {
            throw res.error
        }
    })

export const useGetJournalEntryDatesApi = (): [() => Promise<string[]>, { data: string[] | null, setData: Dispatch<SetStateAction<string[] | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string[] | null>(null)

    const request = useCallback(() => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/journal/entries/dates`)
                .then((res) => res.json() as Promise<ApiResponse<string[]>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string[]);
                        return res.data as string[]
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useGetJournalEntryDatesApiBackground = (): [() => Promise<string[]>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback(() => {
                    setError(null);
            
                    return api.get(`/api/journal/entries/dates`)
                        .then((res) => res.json() as Promise<ApiResponse<string[]>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string[]
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const GET_JOURNAL_ENTRY_API = (id: string) =>
    api.get(`/api/journal/entry/${id}`)
    .then((res) => res.json() as Promise<ApiResponse<JournalEntry>>)
    .then((res) => {
        if (res.success) {
            return res.data as JournalEntry
        } else {
            throw res.error
        }
    })

export const useGetJournalEntryApi = (): [(id: string) => Promise<JournalEntry>, { data: JournalEntry | null, setData: Dispatch<SetStateAction<JournalEntry | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<JournalEntry | null>(null)

    const request = useCallback((id: string) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/journal/entry/${id}`)
                .then((res) => res.json() as Promise<ApiResponse<JournalEntry>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as JournalEntry);
                        return res.data as JournalEntry
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useGetJournalEntryApiBackground = (): [(id: string) => Promise<JournalEntry>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((id: string) => {
                    setError(null);
            
                    return api.get(`/api/journal/entry/${id}`)
                        .then((res) => res.json() as Promise<ApiResponse<JournalEntry>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as JournalEntry
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const GET_JOURNAL_ENTRY_PREVIEW_API = (id: string) =>
    api.get(`/api/journal/entry/${id}/preview`)
    .then((res) => res.json() as Promise<ApiResponse<JournalEntryPreview>>)
    .then((res) => {
        if (res.success) {
            return res.data as JournalEntryPreview
        } else {
            throw res.error
        }
    })

export const useGetJournalEntryPreviewApi = (): [(id: string) => Promise<JournalEntryPreview>, { data: JournalEntryPreview | null, setData: Dispatch<SetStateAction<JournalEntryPreview | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<JournalEntryPreview | null>(null)

    const request = useCallback((id: string) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.get(`/api/journal/entry/${id}/preview`)
                .then((res) => res.json() as Promise<ApiResponse<JournalEntryPreview>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as JournalEntryPreview);
                        return res.data as JournalEntryPreview
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useGetJournalEntryPreviewApiBackground = (): [(id: string) => Promise<JournalEntryPreview>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((id: string) => {
                    setError(null);
            
                    return api.get(`/api/journal/entry/${id}/preview`)
                        .then((res) => res.json() as Promise<ApiResponse<JournalEntryPreview>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as JournalEntryPreview
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const CREATE_JOURNAL_ENTRY_API = (payload: CreateJournalEntryPayload) =>
    api.post(`/api/journal/entry`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useCreateJournalEntryApi = (): [(payload: CreateJournalEntryPayload) => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback((payload: CreateJournalEntryPayload) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.post(`/api/journal/entry`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useCreateJournalEntryApiBackground = (): [(payload: CreateJournalEntryPayload) => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((payload: CreateJournalEntryPayload) => {
                    setError(null);
            
                    return api.post(`/api/journal/entry`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const UPDATE_JOURNAL_ENTRY_API = (id: string, payload: UpdateJournalEntryPayload) =>
    api.put(`/api/journal/entry/${id}`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useUpdateJournalEntryApi = (): [(id: string, payload: UpdateJournalEntryPayload) => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback((id: string, payload: UpdateJournalEntryPayload) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.put(`/api/journal/entry/${id}`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useUpdateJournalEntryApiBackground = (): [(id: string, payload: UpdateJournalEntryPayload) => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((id: string, payload: UpdateJournalEntryPayload) => {
                    setError(null);
            
                    return api.put(`/api/journal/entry/${id}`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

export const DELETE_JOURNAL_ENTRY_API = (id: string) =>
    api.delete(`/api/journal/entry/${id}`)
    .then((res) => res.json() as Promise<ApiResponse<string>>)
    .then((res) => {
        if (res.success) {
            return res.data as string
        } else {
            throw res.error
        }
    })

export const useDeleteJournalEntryApi = (): [(id: string) => Promise<string>, { data: string | null, setData: Dispatch<SetStateAction<string | null>>, setError: Dispatch<SetStateAction<string | null>>, loading: boolean, error: string | null }] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<string | null>(null)

    const request = useCallback((id: string) => {
            setLoading(true);
            setError(null);
            setData(null);
    
            return api.delete(`/api/journal/entry/${id}`)
                .then((res) => res.json() as Promise<ApiResponse<string>>)
                .then((res) => {
                    if (res.success) {
                        setData(res.data as string);
                        return res.data as string
                    } else {
                        throw res.error;
                    }
                })
                .catch((error) => {
                    setError(error);
                    throw error;
                })
                .finally(() => setLoading(false));
        },
 [])
    return [request, { data, setData, loading, error, setError }];
}

export const useDeleteJournalEntryApiBackground = (): [(id: string) => Promise<string>, { error: string | null }] => {
    const [error, setError] = useState<string | null>(null);

    const request = useCallback((id: string) => {
                    setError(null);
            
                    return api.delete(`/api/journal/entry/${id}`)
                        .then((res) => res.json() as Promise<ApiResponse<string>>)
                        .then((res) => {
                            if (res.success) {
                                return res.data as string
                            } else {
                                throw res.error;
                            }
                        })
                        .catch((error) => {
                            setError(error);
                            throw error;
                        });
                },
 [])
    return [request, { error }];
}

