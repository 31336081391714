import { useAsyncError, useRouteError } from "react-router-dom"

export default function ErrorElement() {
    let error = useRouteError() as Error;
    if (!error) error = useAsyncError() as Error;

    return (
        <div>
            <h1>Error</h1>
            <p>{error.message}</p>
        </div>
    )
}