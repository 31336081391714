import InputCheckbox from "@/components/atoms/input-checkbox/input-checkbox";
import Label from "@/components/atoms/label/label";

export enum ChecklistStatus {
    Todo = "todo",
    Done = "done"
};

export interface ChecklistItem {
    id: string;
    text: string;
    status: ChecklistStatus;
}

export interface ChecklistEntryItemProps {
    item: ChecklistItem;
    handleStatusChange: (item: ChecklistItem, status: ChecklistStatus) => void;
}

export default function ChecklistEntryItem({ item, handleStatusChange }: ChecklistEntryItemProps) {
    const handleChange = (checked: boolean): void => {
        handleStatusChange(item, checked ? ChecklistStatus.Done : ChecklistStatus.Todo);
    }

    return (
        <li className='checklist-entry-item'>
            <InputCheckbox
                id={item.id + '-status'}
                defaultValue={item.status === ChecklistStatus.Done}
                onChange={handleChange}
                style="completion"
                label={item.text}
            />
        </li>
    )
}