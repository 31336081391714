import { Outlet } from 'react-router-dom'
import './App.css'
import useTitle from './hooks/use-title'
import PageLayout from './components/molecules/page-layout/page-layout'

function App() {
    return (
        <Outlet />
    )
}

export default App
