import React from 'react';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './router/routes.tsx';
import ServerDataProvider, { ResetData, SetData } from './context/data-context/data-context.tsx';
import { JournalEntry, NavigationLink, PublicUser } from '@/types';
import { hydrateRoot } from 'react-dom/client';

const props = (window as any).__INITIAL_PROPS__;

export type ServerStateData = {
    entriesDate: string;
    entries: JournalEntry[];
    entry: JournalEntry | null;
    entryThread: JournalEntry[];
    entryDates: string[];
    searchEntries: JournalEntry[];
    entryDataError: string | null;
    setData: SetData<ServerStateData>;
    resetData: ResetData;
    user: PublicUser | null;
    navigation: NavigationLink[];
    title: string;
    header: string;
    APP_NAME: string;
    server_error: string | null;
};

const DefaultServerStateData = {
    entries: [],
    entryDataError: null,
    entriesDate: '',
    entry: null,
    entryThread: [],
    searchEntries: [],
    user: null,
}

const Root = (props: any) => {
    const browserRouter = createBrowserRouter(routes, { });

    if (props.props.server_error) {
        browserRouter.navigate('/server-error')
    }

    return (
        <React.StrictMode>
            <ServerDataProvider data={props.props} defaultState={DefaultServerStateData}>
                <RouterProvider router={browserRouter} />
            </ServerDataProvider>
        </React.StrictMode>
    )
}

hydrateRoot(document.getElementById('root')!, <Root props={props} />);