import { useGetEntryThreadApi, useGetJournalEntryApi } from "@/api/endpoints";
import Entry from "@/components/molecules/entry/entry";
import { useData } from "@/context/data-context/data-context";
import useTitle from "@/hooks/use-title";
import { ServerStateData } from "@/main";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export default function SingleEntry() {
    const { entry, entryThread, setData } = useData<ServerStateData>();
    let title = entry?.content.substring(0, 50) || 'Entry';
    useTitle(title);
    const { id } = useParams<{ id: string }>();
    const [getEntry, { loading: loadingEntry }] = useGetJournalEntryApi();
    const [getEntryThread, { loading: loadingThread }] = useGetEntryThreadApi();

    useMemo(() => {
        if (!id || entry?.id === id) return;
        getEntry(id)
            .then(d => {
                getEntryThread(d.id)
                    .then(e => {
                        setData(['entry', 'entryThread'] as any, [d, e]);
                    })
            })
    }, [entry?.id])

    if (!entry) {
        return <div>Entry not found</div>;
    }

    if (loadingEntry || loadingThread) {
        return <div>Loading...</div>;
    }

    return (
        <Entry
            entry={entry}
            entryThread={entryThread}
            afterUpdate={async () => {
                const updatedEntry = await getEntry(entry.id);
                setData('entry', updatedEntry);
            }}
        />
    )
}