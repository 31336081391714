import { cns } from '@/utils/array';
import { Link as RouterLink } from 'react-router-dom';
import './link.scss';

export type LinkProps = {
    children: React.ReactNode;
} & React.ComponentProps<typeof RouterLink>;

export default function Link({ children, ...rest }: LinkProps) {
    const classes = rest.className ? rest.className.split(' ') : [];
    classes.push('link');

    return (
        <RouterLink {...rest} className={cns(classes)}>{children}</RouterLink>
    )
}