import Button from "@/components/atoms/button/button";
import { JournalEntry } from "@/types";
import CreateEntry from "../create-entry/create-entry";
import { useState } from "react";
import { useGetEntryThreadApi } from "@/api/endpoints";
import Entry from "./entry";
import './entry-thread.scss';
import { cns } from "@/utils/array";
import { MdAdd } from "react-icons/md";
import Badge from "@/components/atoms/badge/badge";

export type EntryThreadProps = {
    entry: JournalEntry;
    thread: JournalEntry[];
    creatingThread: boolean;
    setCreatingThread: (creating: boolean) => void;
    setThreadCount: (count: number) => void;
    threadCount: number;
    setThread: (thread: JournalEntry[]) => void;
}

export default function EntryThread({
    entry,
    creatingThread,
    setCreatingThread,
    setThreadCount,
    threadCount,
    thread,
    setThread
}: EntryThreadProps) {
    const [getEntryThread] = useGetEntryThreadApi();

    const handleCreateThread = () => {
        setCreatingThread(true);
    }

    const handleGetEntryThread = () => {
        getEntryThread(entry.id)
            .then((e) => {
                setThread(e);
                setThreadCount(e.length);
            })
            .catch((error) => {
                console.error(error);
            });

    }

    let actionClasses = cns([
        'thread-action',
        thread.length > 0 ? 'has-entries' : '',
        creatingThread ? 'creating' : ''
    ])

    if (entry.parent_id !== null) {
        return null;
    }

    return (
        <div className='thread-wrapper'>
            <div className={actionClasses}>
                {threadCount > 0 && !creatingThread && 
                    (thread.length === 0 ? (
                        <Button type='button' style='custom' onClick={handleGetEntryThread}>
                            View thread <Badge count={threadCount} />
                        </Button>
                    ) : (
                        <Button type='button' style='custom' onClick={() => setThread([])}>
                            Hide thread
                        </Button>
                    ))
                }
                {!creatingThread ? (
                    <Button type='button' style='custom' onClick={handleCreateThread}>
                        <MdAdd size={16} style={{marginRight: '-5px'}} />
                        {threadCount === 0 ? 'Create thread' : 'Add entry'}
                    </Button>
                ) : (
                    <CreateEntry
                        parentId={entry.id}
                        thread={true}
                        editing={true}
                        setIsEditing={(editing) => setCreatingThread(editing)}
                        afterUpdate={handleGetEntryThread}
                        afterCreate={handleGetEntryThread}
                    />
                )}
            </div>

            {thread.length > 0 && (
                <div className="thread-entries">
                    {thread.map((entry) => (
                        <Entry
                            key={entry.id}
                            entry={entry}
                            fromThread={true}
                            afterUpdate={handleGetEntryThread}
                        />  
                    ))}
                </div>
            )}
        </div>
    )
}