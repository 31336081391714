import { useGetJournalEntryPreviewApi } from '@/api/endpoints';
import Link from '../link/link';
import './entry-preview.scss';
import { memo, useMemo } from 'react';

export type EntryPreviewProps = {
    entryId: string;
};

export default memo(function EntryPreview({ entryId }: EntryPreviewProps) {
    let [getJournalEntryPreviewApi, { loading, data: entry, error }] = useGetJournalEntryPreviewApi();

    useMemo(() => {
        if (loading || entry) return;

        getJournalEntryPreviewApi(entryId);
    }, []);

    if (loading) {
        return <Link to={`/journal/entry/${entryId}`}>{entryId}</Link>
    }

    if (!entry || error && !loading) {
        return <Link to={`/journal/entry/${entryId}`}>{error} - {entryId}</Link>
    }
    
    return (
        <Link to={`/journal/entry/${entryId}`}>
            {entry.title}
        </Link>
    );
});