import './form-button-group.scss';

export interface FormButtonGroupProps {
    children: React.ReactNode;
}

export default function FormButtonGroup({ children }: FormButtonGroupProps) {
    return (
        <div className="form-button-group">
            {children}
        </div>
    )
}