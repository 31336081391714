import { useGetMeApi, useUpdateMeApi } from "@/api/endpoints";
import Button from "@/components/atoms/button/button";
import FormButtonGroup from "@/components/atoms/form-button-group/form-button-group";
import Form from "@/components/atoms/form/form";
import InputGroup from "@/components/atoms/input-group/input-group";
import InputSelect from "@/components/atoms/input-select/input-select";
import InputSelectOption from "@/components/atoms/input-select/input-select-option";
import InputText from "@/components/atoms/input-text/input-text";
import SettingsSection, { SettingsSectionEditProps } from "@/components/molecules/settings-section/settings-section";
import { useData } from "@/context/data-context/data-context";
import { ServerStateData } from "@/main";

function Edit({ close }: SettingsSectionEditProps) {
    const { user, setData } = useData<ServerStateData>();
    const [getMe, { loading: loadingMe }] = useGetMeApi();
    const [updateMe, { loading: loadingUpdate }] = useUpdateMeApi();

    const handleSubmit = async (data: any) => {
        let nameParts = data.name.split(' ');
        let firstName = nameParts[0];
        let lastName = nameParts[1] || null;

        await updateMe({
            first_name: firstName,
            last_name: lastName,
            timezone: null
        });

        const user = await getMe();
        setData('user', user);
        close();
    }

    return (
        <Form id='name-form' onSubmit={handleSubmit}>
            <InputGroup id='name'>
                <InputText
                    defaultValue={[user!.first_name, user!.last_name].filter(Boolean).join(' ')}
                />            
            </InputGroup>

            <FormButtonGroup>
                <Button
                    type='submit'
                    disabled={loadingUpdate || loadingMe}
                >Save</Button>
            </FormButtonGroup>
        </Form>
    )
}

function View() {
    const { user } = useData<ServerStateData>();

    return (
        <div>
            {[user!.first_name, user!.last_name].filter(Boolean).join(' ')}
        </div>
    )
}

export default function NameSettings() {
    return (
        <SettingsSection
            title='Name'
            whenEditing={Edit}
            whenViewing={View}
        />
    )
}