import { useEffect, useRef, useState } from 'react';
import './alert.scss';
import { MdClose } from "react-icons/md";
import { cns } from '@/utils/array';

export interface AlertProps {
    children: React.ReactNode;
    type?: 'error' | 'success';
    plain?: boolean;
}

export default function Alert({ children, type, plain }: AlertProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState<boolean>(true);

    const handleClose = () => {
        setVisible(false);
    }

    if (!visible) return null;

    let classes = ['alert', type || 'error', plain ? 'plain' : ''];

    return (
        <div ref={ref} className={cns(classes)}>
            <div className='message'>
                {children}
            </div>
            {!plain && (<div>
                <button onClick={handleClose}>
                    <MdClose />
                </button>
            </div>)}
        </div>
    )
}